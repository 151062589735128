@import '../../styles/variables.scss';

$caret-unit: 15px;

.FeedbackIcon {
  filter: drop-shadow(-1px -1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.15));
  margin-right: 1.25rem;
  margin-bottom: 1.7rem;
  padding: 1rem;
  background-color: $gold;
  color: $white!important;
  border-radius: 5px;
  width: 8rem;
  position: fixed;
  bottom: 0;
  z-index: 10;
  right: 0;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;

  a {
    color: $white;
  }
  
  &::before {
    border: 12px solid transparent;
    border-bottom: ($caret-unit / 2) solid;
    border-bottom: 0;
    height: 0;
    width: 0;
    border-top-color: $gold;
    border-top-width: $caret-unit;
    content: '';
    display: block;
    position: absolute;
    left: 90px;
    bottom: -$caret-unit;
    transform-origin: center;
    transform: rotate(90deg) skew(-(strip-unit($caret-unit))+deg) translateY($caret-unit / 1.5);
    transition: border-color 200ms ease-in-out;
  }

  &:hover {
    background-color: $gold-93;

    &::before {
      border-top-color: $gold-93;
    }
  }
}
