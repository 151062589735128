// @import './styles/typography.scss';
// @import './styles/mediaqueries.scss';
@import './styles/variables.scss';
// @import './styles/layout.scss';

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

body {

    margin: 0;
    padding: 0;

    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background: $cream;

}

html, body, div#root {
    width: 100%;
    height: 100%;
}


