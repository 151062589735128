@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.ModalContainer {

    pointer-events: none;

    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    z-index: 9001;

}

.ModalContainer:before {

    content: '';

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: $cream;
    opacity: 0;
    flex-grow: 1;

    transition: opacity 200ms ease-in-out;

}

.ModalContainer .primary-modal-container,
.ModalContainer .side-modal-container {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;

    &.disabled {
        pointer-events: none;
    }

}

.ModalContainer .primary-modal-container {

    justify-content: center;
    align-items: center;

}

.ModalContainer .side-modal-container > .off-modal {

    flex-grow: 1;
    background: transparent;

    @include mobile {
        display: none;
    }

}

.ModalContainer.open {

    pointer-events: initial;

    &:before {
        opacity: 0.87;
    }

}
