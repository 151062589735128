@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.ShowMoreBlock {

    border-bottom: 2px solid $gold;
    word-break: break-word;

}

.ShowMoreBlock h4 {
    @extend .h4;

    text-transform: uppercase;
    margin-bottom: $paragraph-top;
}

.ShowMoreBlock .DangerousCopy {

    color: $gold;
    margin-bottom: $paragraph-top;

    p {
        color: $gold;
    }

}

.ShowMoreBlock button {
    margin: $paragraph-top * 2 0 $paragraph-top;
}

.ShowMoreBlock .ShowMoreToggle {
    margin: $paragraph-top 0 $paragraph-top;
}
