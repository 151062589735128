/***********/
/* TTOctas */
/***********/
@font-face {
    font-family: 'TTOctas-Light';
    font-weight: 300;
    font-style: normal;
    src: url('/fonts/TTOctas/TTOctas-Light.eot');
    src: url('/fonts/TTOctas/TTOctas-Light.eot') format('embedded-opentype'),
         url('/fonts/TTOctas/TTOctas-Light.woff') format('woff'),
         url('/fonts/TTOctas/TTOctas-Light.ttf') format('ttf');
}

@font-face {
    font-family: 'TTOctas-Regular';
    font-weight: 400;
    font-style: normal;
    src: url('/fonts/TTOctas/TTOctas-Regular.eot');
    src: url('/fonts/TTOctas/TTOctas-Regular.eot') format('embedded-opentype'),
         url('/fonts/TTOctas/TTOctas-Regular.woff') format('woff'),
         url('/fonts/TTOctas/TTOctas-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'TTOctas-Bold';
    font-weight: 700;
    font-style: normal;
    src: url('/fonts/TTOctas/TTOctas-Bold.eot');
    src: url('/fonts/TTOctas/TTOctas-Bold.eot') format('embedded-opentype'),
         url('/fonts/TTOctas/TTOctas-Bold.woff') format('woff'),
         url('/fonts/TTOctas/TTOctas-Bold.ttf') format('ttf');
}

@font-face {
    font-family: 'TTOctas-Black';
    font-weight: 900;
    font-style: normal;
    src: url('/fonts/TTOctas/TTOctas-Black.eot');
    src: url('/fonts/TTOctas/TTOctas-Black.eot') format('embedded-opentype'),
         url('/fonts/TTOctas/TTOctas-Black.woff') format('woff'),
         url('/fonts/TTOctas/TTOctas-Black.ttf') format('ttf');
}

/***********/
/* MinionPro */
/***********/
@font-face {
    font-family: 'MinionPro-Medium';
    font-weight: 500;
    font-style: normal;
    src: url('/fonts/MinionPro/MinionPro-Medium.eot');
    src: url('/fonts/MinionPro/MinionPro-Medium.eot') format('embedded-opentype'),
         url('/fonts/MinionPro/MinionPro-Medium.woff') format('woff'),
         url('/fonts/MinionPro/MinionPro-Medium.ttf') format('ttf');
}

/***********/
/* TTNorms */
/***********/
@font-face {
    font-family: 'TTNorms-Regular';
    font-weight: 400;
    font-style: normal;
    src: url('/fonts/TTNorms/TTNorms-Regular.eot');
    src: url('/fonts/TTNorms/TTNorms-Regular.eot') format('embedded-opentype'),
         url('/fonts/TTNorms/TTNorms-Regular.woff') format('woff'),
         url('/fonts/TTNorms/TTNorms-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'TTNorms-Medium';
    // font-weight: 500;
    font-style: normal;
    src: url('/fonts/TTNorms/TTNorms-Medium.eot');
    src: url('/fonts/TTNorms/TTNorms-Medium.eot') format('embedded-opentype'),
         url('/fonts/TTNorms/TTNorms-Medium.woff') format('woff'),
         url('/fonts/TTNorms/TTNorms-Medium.ttf') format('ttf');
}


@font-face {
    font-family: 'TTNorms-Bold';
    font-weight: 700;
    font-style: normal;
    src: url('/fonts/TTNorms/TTNorms-Bold.eot');
    src: url('/fonts/TTNorms/TTNorms-Bold.eot') format('embedded-opentype'),
         url('/fonts/TTNorms/TTNorms-Bold.woff') format('woff'),
         url('/fonts/TTNorms/TTNorms-Bold.ttf') format('ttf');
}

@font-face {
    font-family: 'TTNorms-ExtraBold';
    font-weight: 800;
    font-style: normal;
    src: url('/fonts/TTNorms/TTNorms-ExtraBold.eot');
    src: url('/fonts/TTNorms/TTNorms-ExtraBold.eot') format('embedded-opentype'),
         url('/fonts/TTNorms/TTNorms-ExtraBold.woff') format('woff'),
         url('/fonts/TTNorms/TTNorms-ExtraBold.ttf') format('ttf');
}
