@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.Timeline {

    @extend .margin-top;

    background: $cream;
    box-shadow: $floating-shadow;
    overflow: hidden;

    @include phone {
        padding-bottom: $padding-top-mobile;
    }

}

.Timeline div.carousel-wrapper {
    position: relative;
    margin-top: $padding-top;
}

.Timeline .Slide {

    @extend .padding-sides;

    position: relative;

    width: 100%;
    padding-top: $padding-top;
    padding-bottom: $padding-top;

    display: flex;
    align-items: flex-start;
    overflow: hidden;

    background-size: cover;
    background-position: center;

    @include phone-micro {
        height: 420px;
    }

    @include mobile {
        box-shadow: $floating-shadow;
    }

}

.Timeline .Slide .wrapper {
    display: flex;
}

.Timeline .Slide .wrapper .content {

    width: 40%;
    padding-right: $paragraph-top;

    h4 {
        @extend .h4;
        color: $gold;
    }

    h5 {
        @extend .h7;
        color: $gold;
    }

    div {
        @extend .body;

        margin-top: $paragraph-top * 2;
    }

}

@include mobile-less {

    .Timeline .Slide .wrapper .content {
        width: 100%;
        padding-right: 0;
        text-align: center;

        h4 {
            color: $cream;
            margin-bottom: $paragraph-top * 2;
        }

        h5 {
            padding: 20px 0;
            color: $cream;
            border-bottom: 1px solid $cream;
        }

        div {
            color: $cream;
            margin-top: $paragraph-top;
        }
    }

    .Timeline .Slide.text-only .wrapper .content {
        h4, h5, div {
            color: $blue;
        }

        h5 {
            border-color: $blue;
        }
    }
}

.Timeline .Slide .wrapper .image {

    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    img {
        width: 100%;
    }

    .caption {
        @extend .footer;
        margin-top: $paragraph-top;
    }

}

.Timeline .Slide.text-only {

    .wrapper .content {
        text-align: center;
        margin: 0 auto;
        flex-grow: 1;
        max-width: 464px;
    }

    @include mobile {
        box-shadow: none;
    }
}

.Timeline .flickity-enabled {
    outline: none;
}

.Timeline .flickity-button {
    display: none;
}

.Timeline .flickity-page-dots {

    display: none;
    justify-content: center;

    width: 100%;
    margin: $paragraph-top 0;
    z-index: 5;

    @include mobile {
        display: flex;
    }

}

.Timeline .flickity-page-dots .dot {

    width: 8px;
    height: 8px;
    margin: 0 8px;

    border-radius: 50%;

    background-color: $blue;
    opacity: .4;

    transition: opacity $anim-duration ease-in-out;

}

.Timeline .flickity-page-dots .dot.is-selected {
    opacity: 1;
}





.App.theme-blue {
    .Timeline .Slide.text-only .wrapper .content {
        h4, h5, div {
            color: $blue;
        }

        h5 {
            border-color: $blue;
        }
    }

    .Timeline .flickity-page-dots .dot {
        background-color: $blue;
    }
}

.App.theme-grey {
    .Timeline .Slide.text-only .wrapper .content {
        h4, h5, div {
            color: $grey;
        }

        h5 {
            border-color: $grey;
        }
    }

    .Timeline .flickity-page-dots .dot {
        background-color: $grey;
    }
}

.App.theme-red {
    .Timeline .Slide.text-only .wrapper .content {
        h4, h5, div {
            color: $red;
        }

        h5 {
            border-color: $red;
        }
    }

    .Timeline .flickity-page-dots .dot {
        background-color: $red;
    }
}
