@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';
@import '../../styles/forms.scss';

.SearchResults {

    @extend .margin-top;
    @extend .padding-sides;

    min-height: 50vh;

}

.SearchResults .wrapper {
    & > h5 {
        @extend .h5;
    }
}

.SearchResults .search-input {

    @extend .form-input;
    @extend .form-input.bordered;

    width: 465px;
    margin-top: $paragraph-top;

    @include mobile {
        width: 100%;
    }

}

.SearchResults .search-results-count {

    @extend .h8;
    margin-top: $paragraph-top;
    margin-bottom: $margin-top;

    @include mobile {
        margin-top: $margin-top;
    }

}

.SearchResults .search-results {
    margin-top: $paragraph-top;
}
