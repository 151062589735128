@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/mediaqueries.scss';
@import '../../styles/layout.scss';

.MatrixGallery {

    @extend .padding-sides;
    @extend .margin-top;

    @include mobile {
        padding: 0;
        margin: 0;
    }

}

.MatrixGallery > .wrapper {

    display: flex;
    flex-wrap: wrap;

}

.MatrixGallery .Tile {

    min-height: 420px;
    padding: $padding-top $padding-sides-mobile;

    background-size: cover;
    background-position: center center;

    color: $cream;
    text-align: center;

    display: flex;
    align-items: center;

    @include tablet {
        min-height: 0;
        padding: $padding-top $padding-sides;
    }

    @include ie11 {
        min-height: 0;
    }

}

.MatrixGallery .Tile div.wrapper {
    max-width: 768px;
}

.MatrixGallery .Tile.blue {
    background-color: $blue;
}

.MatrixGallery .Tile.cream {
    background-color: $cream;
}

.MatrixGallery .Tile.gold {
    background-color: $gold;
}

.MatrixGallery .Tile.grey {
    background-color: $grey;
}

.MatrixGallery .Tile.red {
    background-color: $red;
}

.MatrixGallery .Tile.size-2x1 {
    width: 100%;
    padding: $padding-top $padding-sides;
}

.MatrixGallery .Tile.size-1x1 {

    width: 50%;

    @include tablet {
        width: 100%;
    }

}

.MatrixGallery .Tile h2 {
    @extend .h3;
}

.MatrixGallery .Tile h6 {
    @extend .body;
    margin-top: 20px;
}

.MatrixGallery .Tile button {
    margin-top: $margin-top-button;
}

.MatrixGallery .Tile.no-content {

    min-height: 420px;

    @include mobile {
        min-height: 340px;
    }

}

.MatrixGallery .Tile.no-content button {
    position: absolute;
    bottom: 65px;
    transform: translateX(-50%) translateY(-100%);

    @include mobile {
        bottom: 0;
    }
}
