@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.NewsPreview {

    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $gold;
    padding: $paragraph-top 0;

    h5 {
        @extend .h9;

        color: $blue;
        line-height: 1;
    }

    & > h5 {
        margin-right: $paragraph-top * 2;
    }

    .DangerousCopy p {
        color: $gold;
    }

    a:hover h5 {
        color: $gold;
    }

    .learn-more {
        @extend .h8;
        display: none;
    }

}

@include mobile {

    .NewsPreview {

        flex-direction: column;
        padding: $paragraph-top * 2 $padding-sides-mobile;

        h5 {
            margin-bottom: $paragraph-top;
        }

        .learn-more {

            font-weight: bold;
            line-height: 1;

            display: flex;
            align-items: center;

            margin-top: $paragraph-top;

            span {
                margin-right: 8px;
            }

            .ArrowIcon {
                width: 8px;
            }

        }

    }

}






.App.theme-blue {
    .NewsPreview h5 {
        color: $blue;
    }
}

.App.theme-grey {
    .NewsPreview h5 {
        color: $grey;
    }
}

.App.theme-red {
    .NewsPreview h5 {
        color: $red;
    }
}
