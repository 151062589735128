@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.ImageBlock {

    @extend .padding-sides;
    @extend .margin-top;

    @include mobile {
        padding: 0;
        margin: 0;
    }

}

.ImageBlock.has-margins img {

    @extend .wrapper-width;
    display: block;

}

.ImageBlock img {
    width: 100%;
}

// Special Rules
div.image_block + div.aligned_copy_list .AlignedCopyList,
div.image_block + div.floating_section .FloatingSection {

    margin-top: -$margin-top;

    @include mobile {
        margin-top: 0;
    }

}
