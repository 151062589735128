/* COLORS */
$white: #fff;
$cream: #F9F4E7;
$blue: #003042;
$blue-93: rgba(0, 48, 66, 0.93);
$gold: #897A50;
$gold-93: rgba(137, 122, 80, 0.93);
$grey: #434444;
$grey-93: rgba(67, 68, 68, 0.93);
$red: #A74A30;
$red-20: rgba(167, 74, 48, 0.2);
$red-93: rgba(167, 74, 48, 0.93);
$shadow: rgba(0, 0, 0, 0.13);

/* SIZES */
$padding-sides: 10%;
$padding-sides-vw: 10vw;
$padding-sides-mobile: 5%;
$padding-sides-mobile-vw: 5vw;
$padding-deep-sides: 20%;
$padding-deep-sides-mobile: 10%;
$padding-top: 60px;
$padding-top-extra: $padding-top * 2.5;
$padding-top-mobile: 40px;
$margin-top: 45px;
$margin-top-mobile: 37.5px;
$margin-top-button: 50px;
$paragraph-top: 25px;
$paragraph-max-width: 720px;

/* HEADER SIZES */
$header-height: 146px;
$header-collapsed-height: 132px;
$header-mobile-height: 59px;
$header-mobile-tag-height: 39px;
$header-item-padding: 1.5vw;
$header-dropdown-padding: 1.2vw;

/* MISC */
$floating-shadow-top: 0 -5px 5px 1px rgba(0, 0, 0, .15);
$floating-shadow-bottom: 0 5px 5px 1px rgba(0, 0, 0, .15);
$floating-shadow: 0 0px 10px 1px rgba(0, 0, 0, .15);
$anim-duration: 200ms;
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
