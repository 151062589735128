@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.WriteItOut {

    @extend .padding-sides;
    @extend .margin-top;

    position: relative;

}

.WriteItOut.no-header {

    .wrapper > h6 {
        padding-top: 0;
    }

}

.WriteItOut.no-subheader {

    .wrapper > h6 {
        padding: 20px 0;
    }

}

.WriteItOut.no-header.no-subheader,
.WriteItOut.no-subheader.no-copy {

    .wrapper > h6 {
        padding: 0;
        border-bottom: none;
    }

}

.WriteItOut .wrapper > h2,
.WriteItOut p {
    color: $blue;
}

.WriteItOut .wrapper > h2 {

    @extend .h1;

    /* Flips to h5 on Mobile. */
    @include mobile-less {
        font-family: 'TTOctas-bold', sans-serif;
        font-weight: 700;
        font-size: 36px;
        line-height: 38px;
    }

}

.WriteItOut .wrapper > h6 {

    @extend .h7;
    color: $gold;

    padding: 40px 0;

    border-bottom: 1px solid;
    border-color: $gold;

    @include mobile-less {
        padding: 20px 0;
    }

}





.App.theme-blue {
    .WriteItOut .wrapper > h2,
    .WriteItOut p {
        color: $blue;
    }
}

.App.theme-grey {
    .WriteItOut .wrapper > h2,
    .WriteItOut p {
        color: $grey;
    }
}

.App.theme-red {
    .WriteItOut .wrapper > h2,
    .WriteItOut p {
        color: $red;
    }
}
