@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';

.LoginMenuItem {
    position: relative;
}

.LoginMenuItem > h5 {

    @extend .h9;
    color: $blue;

    position: relative;
    padding: 10px $header-item-padding;
    cursor: pointer;

    span:nth-child(2) {

        @extend .h9;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        white-space: nowrap;

        pointer-events: none;
        opacity: 0;

    }

}

.LoginMenuItem:hover {

    h5 {
        span:nth-child(1) { opacity: 0; }
        span:nth-child(2) { opacity: 1; }
    }

}

.LoginMenuItem.collapsed:hover,
.LoginMenuItem.collapsed.active,
.LoginMenuItem.collapsed.hovered {
    h5 {
        opacity: 1;
    }
}

.LoginMenuItem.collapsed > h5 {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 40px;
    opacity: 1;
}





.App.theme-blue {
    .LoginMenuItem > h5 {
        color: $blue;
    }
}

.App.theme-grey {
    .LoginMenuItem > h5 {
        color: $grey;
    }
}

.App.theme-red {
    .LoginMenuItem > h5 {
        color: $red;
    }
}

.LoginMenuItem .dropdown {

    @extend .h9;
    color: $cream;

    position: absolute;
    top: 100%;
    left: 0;

    background: $blue;

    padding: $header-dropdown-padding;
    padding-top: $header-dropdown-padding * 0.65;
    padding-bottom: $header-dropdown-padding;
    min-width: 224px;

    border-top: 2px solid $gold;

    visibility: hidden;

}

.LoginMenuItem:hover .dropdown {
    visibility: visible;
}

.LoginMenuItem .dropdown a {

    display: block;
    opacity: 0;

    color: $cream;
    font-weight: 700;

    transform: translateX(-15px);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

}

.LoginMenuItem .dropdown a:hover {
    color: $gold;
}

.LoginMenuItem:hover .dropdown a {
    opacity: 1;
    transform: translateX(0);
}

@for $i from 1 through 100 {
    .LoginMenuItem .dropdown a:nth-child(#{$i}) {
        transition-delay: $i * 0.05s;
    }
}

.LoginMenuItem .dropdown a li {

    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

}

.LoginMenuItem .dropdown a li svg {
    width: 12px;
    height: 12px;
    margin-left: 15px;
}

.LoginMenuItem .dropdown a:hover li .ArrowIcon {
    fill: $gold;
}
