@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/mediaqueries.scss';
@import '../../styles/layout.scss';

.HeroCopy {

    @extend .padding-sides;

    width: 100%;
    padding-top: $padding-top;
    padding-bottom: $padding-top;

    display: flex;
    align-items: center;

    text-align: center;
    background-color: $cream;

    &.shadowed {
        box-shadow: $floating-shadow-bottom;
        min-height: calc(100vw / 3.5);
        padding-bottom: $padding-top * 2;
    }

    @include ie11 {
        min-height: 0;
    }

    @include mobile-less {
        text-align: left;
        padding-bottom: $padding-top;
    }

}

.HeroCopy .wrapper {

    max-width: 945px;
    margin: 0 auto;

}

.HeroCopy h2 {

    @extend .h1;

    /* Flips to h5 on Mobile. */
    @include mobile-less {
        font-family: 'TTOctas-bold', sans-serif;
        font-weight: 700;
        font-size: 36px;
        line-height: 38px;
    }

}

.HeroCopy h6 {

    @extend .h7;
    color: $gold;

    padding: 40px 0;

    border-bottom: 1px solid $gold;

    @include mobile-less {
        padding: $paragraph-top 0;
    }

}

.HeroCopy p {

    @extend .body;
    margin-top: $paragraph-top;

    @include mobile-less {
        margin-top: 40px;
    }

}

.hero_copy + .aligned_copy_list .AlignedCopyList {
    box-shadow: none !important;
}
