@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';

.SearchMenuItem {
    position: relative;
}

.SearchMenuItem > h5 {

    @extend .h9;
    color: $blue;

    position: relative;
    padding: 10px $header-item-padding;
    cursor: pointer;
    align-items: center;

    span {
        display: flex;
        align-items: center;

        svg {
            margin-right: 5px;
        }
    }

    span:nth-child(2) {

        @extend .h9;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        white-space: nowrap;

        pointer-events: none;
        opacity: 0;

        svg {
            stroke-width: 2px;
        }

    }

}

.SearchMenuItem:hover {

    h5 {
        span:nth-child(1) { opacity: 0; }
        span:nth-child(2) { opacity: 1; }
    }

}

.SearchMenuItem.collapsed:hover,
.SearchMenuItem.collapsed.active,
.SearchMenuItem.collapsed.hovered {
    h5 {
        opacity: 1;
    }
}

.SearchMenuItem.collapsed > h5 {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 40px;
    opacity: 1;
}





.App.theme-blue {
    .SearchMenuItem > h5 {
        color: $blue;
    }
}

.App.theme-grey {
    .SearchMenuItem > h5 {
        color: $grey;
    }
}

.App.theme-red {
    .SearchMenuItem > h5 {
        color: $red;
    }
}
