@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.ModalCloseIcon {

    position: absolute;
    top: $padding-top;
    left: $padding-top;

    cursor: pointer;

    svg g {
        stroke: $cream;
        transition: stroke 200ms ease-in-out;
    }

    &:hover {
        svg g {
            stroke: $gold;
        }
    }

    @include mobile {
        top: $padding-sides-mobile;
        left: $padding-sides-mobile;
    }

}
