@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.LoginMobileMenuItem {

    position: relative;

    padding: 0 $padding-sides-mobile;
    margin: 25px auto;

}

.LoginMobileMenuItem h5 {

    @extend .h9;

    color: $gold;
    display: flex;
    justify-content: space-between;
    align-items: center;

    line-height: 50px;
}

.LoginMobileMenuItem .dropdown {

    @extend .h8;
    color: $cream;

    margin: 0 $header-item-padding;
    padding-bottom: $padding-top-mobile * 0.5;

    @include mobile-less {
        margin: 0 25px;
    }

    display: none;

}

.LoginMobileMenuItem.expanded .dropdown {
    display: block;
}

.LoginMobileMenuItem .dropdown a {

    display: block;

    color: $cream;
    line-height: 50px;
    font-weight: 700;

}

.LoginMobileMenuItem.expanded .dropdown a {

    animation: 0.5s ease-in-out 1 menu-item-in;
    animation-fill-mode: forwards;

    opacity: 0;

}

.LoginMobileMenuItem .dropdown a li {

    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

}

.LoginMobileMenuItem .dropdown a li svg {
    width: 12px;
    height: 12px;
    margin-left: 15px;
}

@for $i from 1 through 100 {
    .LoginMobileMenuItem .dropdown a:nth-child(#{$i}) {
        animation-delay: $i * 0.05s;
    }
}

@keyframes menu-item-in {
    from {
        opacity: 0;
        transform: translateX(-15px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
