@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.BlogPreview {

    @extend .margin-top;

    padding: $padding-top * 1.5 $padding-sides;
    border-top: 2px solid $gold;

    @include desktop {
        padding: $padding-top * 1.5 $padding-sides-mobile;
    }

    @include mobile {
        padding: $padding-top $padding-sides-mobile 0;
    }

    @include mobile-less {
        padding: $padding-top / 2 0 0;
    }

}

div.BlogPreview img.title-treatment {

    width: 100%;
    margin-bottom: $padding-top * 1.5;

    @include mobile-less {
        padding: 0 $padding-sides-mobile * 1.5;
        margin-bottom: $padding-top * 0.5;
    }

}

div.BlogPreview img.title-treatment.desktop {
    @include mobile-less {
        display: none;
    }
}

div.BlogPreview img.title-treatment.mobile {
    padding: $padding-sides-mobile * 2 0;
    margin-bottom: 0;
}

div.BlogPreview div.mobile-title-treatment-wrapper {

    display: none;
    padding: 0 $padding-sides-mobile;

    @include mobile-less {
        display: block;
    }

}

div.BlogPreview div.FeaturedArticle {
    margin-bottom: $margin-top;
}

div.BlogPreview div.read-all-content {
    @extend .margin-top;

    margin-top: $padding-top * 2;

    @include mobile-less {
        margin-top: $margin-top;
        margin-left: $padding-sides-mobile;
        margin-right: $padding-sides-mobile;
    }
}

div.BlogPreview div.read-all-content h3 {
    @extend .h6;

    color: $blue;
    margin-bottom: $margin-top;

    @include mobile-less {
        color: $red;
    }
}






.App.theme-blue {
    div.BlogPreview div.read-all-content h3 {
        color: $blue;
    }
}

.App.theme-grey {
    div.BlogPreview div.read-all-content h3 {
        color: $grey;
    }
}

.App.theme-red {
    div.BlogPreview div.read-all-content h3 {
        color: $red;
    }
}
