@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.SearchMobileMenuItem {

    position: relative;
    padding: 0 $padding-sides-mobile;
    margin: 0 auto;

}

.SearchMobileMenuItem .search-input {

    display: flex;
    align-items: center;

    height: 49px;
    padding-left: $header-dropdown-padding * 2;
    border: 1px solid $cream;

    svg g {
        stroke: $cream;
    }

    input {
        @extend .h8;

        color: $cream;
        border: none;
        background: none;

        margin: 0;
        padding: 0;

        margin-left: $header-dropdown-padding;
        margin-right: $header-dropdown-padding;
        flex-grow: 1;

        &::placeholder {
            color: $cream;
        }
    }

    .submitter {
        @extend .h9;

        appearance: none;
        background: none;
        border: none;

        justify-self: flex-end;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 49px;
        height: 100%;
        padding-left: 10px;
        padding-right: 10px;

        color: $cream;
        border-left: 1px solid $cream;
        cursor: pointer;
    }

}

.SearchMobileMenuItem.disabled {
    pointer-events: none;
    opacity: 0.33;
}
