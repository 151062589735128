@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.HeaderMobile {

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: $header-mobile-height;

    background: $cream;

    z-index: 1000;

    & + * {
        padding-top: $header-mobile-height;
    }

}

.App.has-header-tag {
    .HeaderMobile {
        & + * {
            padding-top: calc(#{ $header-mobile-height } + #{ $header-mobile-tag-height });
        }
    }
}
