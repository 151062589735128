@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.DownloadablesCondensed {

    @extend .padding-sides;
    @extend .margin-top;

    position: relative;

    &.remove-top-margin {
        margin-top: 0;
    }

}

.DownloadablesCondensed h5 {

    @extend .h5;

    padding-bottom: 13px;
    margin-top: $margin-top * 2;
    margin-bottom: $margin-top;
    border-bottom: 1px solid;

    @include mobile-less {
        margin-bottom: $margin-top-mobile;
    }

}

.DownloadablesCondensed .files {

    margin-bottom: $paragraph-top;

    @include tablet-less {
        margin-bottom: $margin-top-mobile;
    }

}

.DownloadablesCondensed .files ul {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    list-style: none;

    li {

        width: 33.33%;
        margin: 5px 0;

        @include mobile-less {
            width: 100%;
        }

    }

    li a {

        display: inline-flex;
        align-items: center;
        color: $blue;

    }

    li a .HoverHeader:hover {
        color: $gold;
    }

    li a .header-content {

        text-transform: capitalize;

        &::before {
            content: '>';
            margin-right: 10px;
            line-height: 1;
        }

    }

    li a img {
        margin-left: 10px;
    }

}

.DownloadablesCondensed .files ul + span {
    display: inline-block;
    margin-top: 32px;
}

.downloadables_condensed + .downloadables_condensed .DownloadablesCondensed {

    margin-top: 0;

    h5 {
        margin-top: 0;
    }

    @include mobile-less {
        margin-top: 0;
    }

}





.App.theme-blue {
    .DownloadablesCondensed .files ul li a {
        color: $blue;
    }
}

.App.theme-grey {
    .DownloadablesCondensed .files ul li a {
        color: $grey;
    }
}

.App.theme-red {
    .DownloadablesCondensed .files ul li a {
        color: $red;
    }
}
