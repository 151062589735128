@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/mediaqueries.scss';
@import '../../styles/layout.scss';

.HeroImage {

    @extend .padding-sides;

    width: 100%;
    min-height: calc(100vw / 3.5);
    padding-top: $padding-top;
    padding-bottom: $padding-top;
    margin-bottom: 64px;

    background-size: cover;
    background-position: center center;

    display: flex;
    align-items: center;

    text-align: center;

    @include mobile {
        margin-bottom: 0;
    }

}

.HeroImage h2,
.HeroImage h6 {
    color: $cream;
}

.HeroImage h2 {
    @extend .h1;
}

.HeroImage h6 {
    @extend .h7;
    margin-top: 20px;
}

.HeroImage button {

    margin-top: $padding-top;

}
