@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.FadForm {

    @extend .h8;
    color: $gold;

    & > div:not(:last-of-type) {
        margin-bottom: $paragraph-top;
    }

    & > a > div {
        margin-top: 15px;
    }

    .h8 {
        line-height: 1.2rem;
    }

    .h9 {
        color: $blue;
    }

    button {
        margin-top: $paragraph-top * 2;
    }

}

.FadForm .question {

    .h8 {
        color: $blue;
    }

}

// Theme: Cream
.FadForm.cream {

    color: $cream !important;

    .h9 {
        color: $gold !important;
    }

    .question {

        .h8 { color: $cream !important; }

        .RadioInput .Radio {
            span {
                color: $cream !important;

                svg {
                    stroke: $cream !important;
                }
            }

            span:nth-child(2) {

                color: $cream !important;

                svg {
                    fill: $cream !important;
                    stroke: $cream !important;
                }
            }
        }

    }

}





.App.theme-blue {
    .FadForm .h9, .FadForm .question .h8 {
        color: $blue;
    }
}

.App.theme-grey {
    .FadForm .h9, .FadForm .question .h8 {
        color: $grey;
    }
}

.App.theme-red {
    .FadForm .h9, .FadForm .question .h8 {
        color: $red;
    }
}
