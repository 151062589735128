@import './fonts.scss';
@import './mediaqueries.scss';
@import './variables.scss';

.h1 {

    font-family: 'TTOctas-Black', sans-serif;
    font-weight: 900;
    font-size: 72px;
    line-height: 72px;
    letter-spacing: .05em;
    text-transform: uppercase;

    @include tablet {
        font-size: 55px;
        line-height: 58px;
    }

    @include mobile-less {
        font-size: 38px;
        line-height: 42px;
    }

    @include phone-micro {
        font-size: 32px;
        line-height: 36px;
    }

}

.h2 {

    font-family: 'TTOctas-Regular', sans-serif;
    font-weight: 400;
    font-size: 50px;
    line-height: 53px;
    letter-spacing: .0379em;
    text-transform: uppercase;

}

.h3 {

    font-family: 'TTOctas-Regular', sans-serif;
    font-weight: 400;
    font-size: 63px;
    line-height: 63px;
    letter-spacing: .05em;
    text-transform: uppercase;

    @include tablet {
        font-size: 50px;
        line-height: 53px;
    }

}

.h4 {

    font-family: 'TTOctas-Light', sans-serif;
    font-weight: 300;
    font-size: 63px;
    line-height: 63px;
    letter-spacing: .05em;

    @include mobile-less {
        font-size: 50px;
        line-height: 53px;
    }

}

.h5 {

    font-family: 'TTOctas-Bold', sans-serif;
    font-weight: 700;
    font-size: 55px;
    line-height: 63px;
    letter-spacing: .05em;
    text-transform: uppercase;

    @include mobile-less {
        font-size: 36px;
        line-height: 38px;
    }

}

.h6 {

    font-family: 'MinionPro-Regular', serif;
    font-weight: 400;
    font-size: 48px;
    line-height: 49px;
    letter-spacing: .01em;

    @include tablet-less {
        font-size: 32px;
        line-height: 34px;
    }

}

.h7 {

    font-family: 'MinionPro-Medium', serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 32px;
    letter-spacing: .01em;

    @include tablet {
        font-size: 18px;
        line-height: 21px;
    }

}

.h8 {

    font-family: 'TTNorms-Medium', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    letter-spacing: 0.02rem;

    /* @TODO Mobile sizing. */

}

.h9 {

    font-family: 'TTNorms-ExtraBold', sans-serif;
    font-weight: 800;
    font-size: 13px;
    line-height: 30px;
    letter-spacing: 0.02rem;

    /* @TODO Mobile sizing. */

}

.h10 {

    font-family: 'TTNorms-Bold', sans-serif;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 3px;
    text-transform: uppercase;

}

.body {

    font-family: 'TTNorms-Regular', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: .005em;
    text-transform: initial;

}

.footer {

    font-family: 'TTNorms-Regular', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: .00266em;

}

.FeedbackIcon {
    font-family: 'TTNorms-Bold', sans-serif;
    font-weight: 700;
    font-size: 0.813rem;
    line-height: 1.2rem;
    letter-spacing: 3px;
    text-transform: uppercase;
}

a, a:visited {
    color: $gold;
}

a:hover, a:active {
    color: $blue;
}
