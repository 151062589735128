@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

$modal-margin: $paragraph-top * 0.8;

.VideoModal {

    position: relative;
    padding: $modal-margin;

    width: 80%;
    background: $blue;
    opacity: 0;

    @include mobile-less {
        width: 98%;
        padding: $padding-sides-mobile * 0.5;
        margin: auto $padding-sides-mobile * 0.5;
    }

    width: calc(100% - #{$modal-margin} * 2);
    height: calc((100vw - #{$modal-margin} * 4) * 9/16 + (#{$modal-margin} * 2));

}

.VideoModal.calc-by-height {
    width: calc((100vh - #{$modal-margin} * 4) * 16/9 + (#{$modal-margin} * 2));
    height: calc(100% - #{$modal-margin} * 2);
}

.VideoModal .ModalCloseIcon {

    top: $paragraph-top * 0.4;
    left: $paragraph-top * 0.4;

    background: $blue;
    padding: $paragraph-top * 0.4;
    max-height: 90vh;

    z-index: 9999;

    @include mobile-less {

        top: $padding-sides-mobile * 0.25;
        left: $padding-sides-mobile * 0.25;

        padding: $padding-sides-mobile * 0.5;

    }

}

.VideoModal .content {
    width: 100%;
    height: 100%;
}

.VideoModal .video-wrapper {

    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    width: 100%;
    height: 100%;
    padding: 0;

}

.VideoModal .video-wrapper iframe,
.VideoModal .video-wrapper object,
.VideoModal .video-wrapper embed {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}

.VideoModal.open {
    opacity: 1;
}





.App.theme-blue {
    .VideoModal, .VideoModal .ModalCloseIcon {
        background: $blue;
    }
}

.App.theme-grey {
    .VideoModal, .VideoModal .ModalCloseIcon {
        background: $grey;
    }
}

.App.theme-red {
    .VideoModal, .VideoModal .ModalCloseIcon {
        background: $red;
    }
}
