@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.FadExpandMenu {
    padding-bottom: $padding-top-mobile * 0.5;
}

.FadExpandMenu .FadForm {
    .h9 {
        color: $gold;
    }
}





.App.theme-blue {
    .FadExpandMenu .FadForm .h9 {
        color: $gold;
    }
}

.App.theme-grey {
    .FadExpandMenu .FadForm .h9 {
        color: $gold;
    }
}

.App.theme-red {
    .FadExpandMenu .FadForm .h9 {
        color: $gold;
    }
}
