@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.HighlightCopyBlock {

    @extend .body;

}

.HighlightCopyBlock .highlight {
    font-family: 'TTNorms-ExtraBold';
    font-weight: bold;
}
