@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.AlignedCopyList {

    @extend .padding-sides;
    @extend .margin-top;

    position: relative;
    padding-top: $margin-top * 2;
    padding-bottom: $margin-top * 2;

    box-shadow: $floating-shadow;
    z-index: 5;

    @include mobile-less {
        padding-bottom: $margin-top-mobile;
    }

}

.AlignedCopyList .header-container {

    margin-bottom: $padding-top;

    &.left { text-align: left; }
    &.center { text-align: center; }
    &.right { text-align: right; }

    @include mobile-less {
        margin-bottom: $padding-top-mobile;
    }

}

.AlignedCopyList .header-container h1 {
    @extend .h1;
    margin-bottom: $paragraph-top;
}

.AlignedCopyList .header-container p {

    @extend .body;

    color: $gold;
    margin: 0 auto;
    width: 60%;

    @include tablet-less {
        width: 100%;
    }

}

.AlignedCopyList .copy-container div.copy-wrapper {

    margin-bottom: $padding-top;

    &:last-of-type {
        margin-bottom: 0;
    }

}

.AlignedCopyList .copy-container.line-between div.copy-wrapper {

    padding: $padding-top 0;
    margin-bottom: 0;
    border-bottom: 1px solid $gold;

    &:last-of-type {
        padding-bottom: 0;
        border-bottom: none;
    }

    .DangerousCopy {
        margin: 0 auto;
        width: 60%;

        @include tablet-less {
            width: 100%;
        }
    }

}

.AlignedCopyList .copy-container.line-per-item div.copy-wrapper {

    margin-bottom: $margin-top * 2;
    padding: $paragraph-top 0;
    border-bottom: 1px solid $gold;

    &:last-of-type {
        margin-bottom: 0;
    }

    @include mobile-less {
        margin-bottom: $margin-top-mobile;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

}

.AlignedCopyList .DangerousCopy h1, .AlignedCopyList .DangerousCopy h2,
.AlignedCopyList .DangerousCopy h3, .AlignedCopyList .DangerousCopy h4,
.AlignedCopyList .DangerousCopy h5, .AlignedCopyList .DangerousCopy h6 {
    @extend .h5;

    padding-top: 0;
}

.AlignedCopyList .DangerousCopy p em {
    color: $gold;
    font-style: normal;
}

div.aligned_copy_list:last-of-type .AlignedCopyList {
    box-shadow: $floating-shadow-top;
}
