@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.Footer {

    @extend .padding-sides;

    background: $blue;
    padding-top: $margin-top;
    padding-bottom: $margin-top;
    margin-top: 0;

    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.Footer .scroll-top {

    position: absolute;
    top: 0;
    left: 50%;
    width: 50px;
    height: 50px;

    display: none;
    justify-content: center;
    align-items: center;
    z-index: 10;

    transform: translateX(-50%) translateY(-50%);
    background: $gold;
    border-radius: 50%;
    cursor: pointer;

    @include mobile {
        display: flex;
    }

}

.Footer .logo {

    $min-margin: 45;
    $max-margin: 320;
    $min-width: 1024;
    $max-width: 1440;
    // https://css-tricks.com/snippets/css/fluid-typography/
    // calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])))
    margin-right: calc(#{$min-margin}px + (#{$max-margin} - #{$min-margin}) * ((100vw - #{$min-width}px) / (#{$max-width} - #{$min-width})));

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 160px;

        font-size: 0.75em;
        font-weight: bold;
        text-align: center;
        line-height: 1.4;

        img {
            width: 100%;
        }

        div {
            width: 160%;
            margin-top: 35px;
            letter-spacing: 1px;

            @include tablet-less {
                margin-top: 20px;
            }
        }
    }


}

.Footer .footer-menus {

    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    flex-wrap: wrap;

    .FooterMenu, .FooterSocialMenu {
        width: 31.5%;
    }

}

@include tablet-less {

    .Footer {

        padding-top: $margin-top-mobile;
        padding-bottom: $margin-top-mobile;

        flex-direction: column;
        align-items: stretch;

        width: 100%;
    }

    .Footer .logo {

        margin-right: 0;

        img {
            max-width: 100px;
        }

    }

    .Footer .footer-menus {

        .FooterMenu, .FooterSocialMenu {
            width: 48.5%;
            margin-top: $margin-top-mobile;
        }

    }

}





.App.theme-blue {
    .Footer {
        background: $blue;
    }
}

.App.theme-grey {
    .Footer {
        background: $grey;
    }
}

.App.theme-red {
    .Footer {
        background: $red;
    }
}
