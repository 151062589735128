@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.HeaderMobileMenu {

    height: calc(100vh - #{ $header-mobile-height });
    width: 100%;
    padding: $padding-top-mobile 0;

    background: $blue;
    overflow: auto;

}





.App.theme-blue .HeaderMobileMenu {
    background: $blue;
}

.App.theme-grey .HeaderMobileMenu {
    background: $grey;
}

.App.theme-red .HeaderMobileMenu {
    background: $red;
}
