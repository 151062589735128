@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.FooterSocialMenu > h6 {

    @extend .footer;

    color: $cream;

    padding-bottom: $paragraph-top;
    margin-bottom: $paragraph-top;
    border-bottom: 2px solid $gold;

    @include tablet-less {
        padding-bottom: $paragraph-top * 0.5;
        margin-bottom: $paragraph-top * 0.5;
    }

}

.FooterSocialMenu .social {

    display: flex;
    align-items: center;
    margin-bottom: 15px;

    img {
        margin-right: 10px;
        width: 20px;
    }

    h6 {
        @extend .footer;
        color: $cream;
    }

    h6:hover {
        color: $gold;
    }

}
