@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.FadDropdown {

    @extend .h8;
    color: $gold;

    position: absolute;
    top: auto;
    left: $header-item-padding - $header-dropdown-padding;
    padding: $header-dropdown-padding;
    padding-top: $header-dropdown-padding;
    padding-bottom: $header-dropdown-padding;
    min-width: 304px;

    border: 2px solid $gold;
    background: $cream;
    box-shadow: 2px 2px 5px 2px $shadow;

    z-index: 10;

    transition: visibility 0s, opacity 0.2s ease-in-out;
    visibility: hidden;
    opacity: 0;

    &.visible {
        visibility: visible;
        opacity: 1;
    }

}
