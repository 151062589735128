@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.IFrameModal {

    position: absolute;
    top: 0;
    right: 0;
    padding: $paragraph-top * 0.8;

    width: 60%;
    height: 100%;
    background: $blue;
    opacity: 0.98;

    transform: translateX(100%);
    transition: transform 800ms $ease-in-out-cubic;

    @include desktop {
        width: 80%;
    }

    @include tablet-less {
        width: 100%;
    }

}

.IFrameModal .ModalCloseIcon {

    top: $paragraph-top * 0.4;
    left: $paragraph-top * 0.4;

    background: $blue;
    padding: $paragraph-top * 0.4;

}

.IFrameModal .content {
    width: 100%;
    height: 100%;
}

.IFrameModal.open {
    transform: translateX(0%);
}

.IFrameModal iframe {
    width: 100%;
    height: 100%;
}


.App.theme-blue {
    .IFrameModal, .IFrameModal .ModalCloseIcon {
        background: $blue;
    }
}

.App.theme-grey {
    .IFrameModal, .IFrameModal .ModalCloseIcon {
        background: $grey;
    }
}

.App.theme-red {
    .IFrameModal, .IFrameModal .ModalCloseIcon {
        background: $red;
    }
}
