@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.ModalCloseTrigger {

    position: absolute;
    bottom: $padding-top * 1.5;
    left: 45px;

    display: flex;
    align-items: center;

    cursor: pointer;

    .icon {

        width: 50px;
        height: 50px;
        border: 2px solid $cream;

        display: flex;
        justify-content: center;
        align-items: center;

        .ArrowIcon {
            font-family: 'TTNorms-Bold';
        }
    }

    span {
        @extend .h10;

        color: $cream;
        margin-left: $paragraph-top;
    }

    @include mobile {
        display: none;
    }

}

.ModalCloseTrigger:hover {

    .icon {
        border-color: $gold;
        transition: border-color 200ms ease-in-out;

        .ArrowIcon {
            fill: $gold;
            transition: fill 200ms ease-in-out;
        }
    }

    span {
        color: $gold;
        transition: color 200ms ease-in-out;
    }

}
