@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.Downloadables {

    @extend .padding-sides;
    @extend .margin-top;

    position: relative;

}

.Downloadables h5 {

    @extend .h5;

    padding-bottom: 13px;
    margin-bottom: $margin-top;

    @include mobile-less {
        margin-bottom: $margin-top-mobile;
    }

}

.Downloadables .block-content-wrapper {

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-bottom: 1px solid $gold;

    @include tablet-less {
        flex-direction: column;
    }

}

.Downloadables .block-content-wrapper .copy {

    width: 50%;
    margin-bottom: $paragraph-top;

    @include tablet-less {
        width: 100%;
    }

}

.Downloadables .block-content-wrapper .copy .DangerousCopy {

    color: $gold;
    margin-bottom: $paragraph-top;

    p:first-of-type {
        margin-top: 0;
    }

    p {
        color: $gold;
    }
}

.Downloadables .block-content-wrapper .copy .ShowMoreToggle {
    margin: $paragraph-top 0 $paragraph-top;
}

.Downloadables .files {

    margin-bottom: $paragraph-top;

    @include tablet-less {
        margin-bottom: $margin-top-mobile;
    }

}

.Downloadables .files ul {

    list-style: none;

    li {

        margin: 5px 0;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

    }

    li a {

        display: inline-flex;
        align-items: center;
        color: $blue;

    }

    li a .HoverHeader:hover {
        color: $gold;
    }

    li a .header-content {

        text-transform: capitalize;

        &::before {
            content: '>';
            margin-right: 10px;
            line-height: 1;
        }

    }

    li a img {
        margin-left: 10px;
    }

}

.Downloadables .files ul + span {
    display: inline-block;
    margin-top: 32px;
}

.downloadables + .downloadables .Downloadables {

    @include mobile-less {
        margin-top: 0;
    }

}





.App.theme-blue {
    .Downloadables .files ul li a {
        color: $blue;
    }
}

.App.theme-grey {
    .Downloadables .files ul li a {
        color: $grey;
    }
}

.App.theme-red {
    .Downloadables .files ul li a {
        color: $red;
    }
}
