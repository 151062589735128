@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';
@import '../../styles/forms.scss';

.SearchDropdown {

    position: absolute;
    top: auto;
    right: $header-item-padding - $header-dropdown-padding;
    width: 304px;

    border: 2px solid $gold;
    background: $cream;
    box-shadow: 2px 2px 5px 2px $shadow;
    z-index: 10;

    transition: visibility 0s, opacity 0.2s ease-in-out;
    visibility: hidden;
    opacity: 0;

    &.visible {
        visibility: visible;
        opacity: 1;
    }

    &:after {
        $hitbox-extension: 30px;

        content: '';

        position: absolute;
        top: -$hitbox-extension * 0.5;
        right: -$hitbox-extension;
        width: calc(304px + #{$hitbox-extension} * 2);
        height: calc(49px + #{$hitbox-extension} * 1.5);

        z-index: -1;
    }

}

.SearchDropdown .search-input {
    @extend .form-input;
}

.SearchDropdown .search-results {
    border-top: 2px solid $gold;

    & > div:first-of-type {
        padding: $header-dropdown-padding * 0.5 $header-dropdown-padding;
    }
}

.SearchDropdown.disabled {
    .submitter {
        pointer-events: none;
    }
}
