@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';
@import '../../styles/forms.scss';

.NewsArticle {

    @extend .margin-top;
    @extend .padding-sides;

    margin-top: $margin-top * 2;
    color: $blue;

    @include mobile {
        margin-top: $margin-top;
    }

}

.NewsArticle .header {
    @extend .h5;
}

.NewsArticle .date {

    @extend .h7;
    color: $gold;

    margin-top: $margin-top;

    @include mobile {
        margin-top: $margin-top * 0.5;
    }

}

.NewsArticle .copy {

    margin: $margin-top 0;
    padding-bottom: $margin-top;

    border-top: 1px solid $gold;
    border-bottom: 1px solid $gold;

}

.NewsArticle .share {

    margin-top: $margin-top * 2;
    max-width: 400px;

    @include mobile {
        margin-top: $margin-top-mobile * 2;
    }

}

.NewsArticle .share h5 {
    @extend .h6;
    font-size: 25px;
    line-height: 1.2;
}

.NewsArticle .share ul {

    display: inline-flex;
    align-items: center;
    margin-top: $margin-top * 0.5;

    li {
        width: 30px;
        margin-right: 30px;

        img {
            width: 100%;
        }
    }

    li:hover {
        cursor: pointer;
        opacity: 0.6;
        transition: opacity $anim-duration ease;
    }

}

.NewsArticle .share .form-container {

    @extend .form-container;

    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;
    margin: $margin-top * 0.5 0 0;

    .form-input {
        margin: 0;
        margin-right: $paragraph-top * 0.5;
    }

    & > .email-message {

        @extend .h8;

        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(100%);

    }

    & > span.disabled {
        opacity: 0.6;
        pointer-events: none;
    }

    @include mobile {

        flex-direction: column;
        align-items: flex-start;

        .form-input {
            margin-bottom: 15px;
        }

    }

}

.NewsArticle .source, .author {
    @extend .h8;
    color: $gold;
}

.NewsArticle .source {
    margin-top: $margin-top;
}

.NewsArticle .author {
    margin-top: $margin-top;
}

.NewsArticle .buttons {

    display: flex;
    flex-direction: column;
    padding: $margin-top * 2 0 $margin-top * 2;

    .Button {
        margin-top: 0;
    }

    @include mobile {
        padding: $margin-top-mobile * 2 0 $margin-top-mobile * 2;
    }

}
