@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.HeaderMobileLogoBar {

    display: flex;
    justify-content: center;
    align-items: center;

    height: $header-mobile-height;
    width: 100%;
    border-bottom: 2px solid $gold;

}

.HeaderMobileLogoBar > a {

    img {
        height: $header-mobile-height * 0.5;
    }

}

.HeaderMobileLogoBar div.hamburglar {

    position: absolute;
    top: 50%;
    right: $header-mobile-height * 0.33;
    transform: translateY(-50%);

}

.HeaderMobileLogoBar div.hamburglar div.patty {

    width: 26px;
    height: 2px;
    border-radius: 2px;

    background-color: $blue;

    &:nth-child(2) { margin: 5px 0; }

    transition: all $anim-duration ease;

}

.HeaderMobileLogoBar div.hamburglar.open div.patty {
    &:nth-of-type(1) { transform: translateY(5px) translateY(100%) rotate(45deg); }
    &:nth-of-type(2) { opacity: 0; }
    &:nth-of-type(3) { transform: translateY(-5px) translateY(-100%) rotate(-45deg); }
}






.App.theme-blue {
    .HeaderMobileLogoBar div.hamburglar div.patty {
        background-color: $blue;
    }
}

.App.theme-grey {
    .HeaderMobileLogoBar div.hamburglar div.patty {
        background-color: $grey;
    }
}

.App.theme-red {
    .HeaderMobileLogoBar div.hamburglar div.patty {
        background-color: $red;
    }
}
