@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';

.SearchDropdownResults *:first-child .result {
    margin-top: 0;
}

.SearchDropdownResults *:last-child .result {
    margin-bottom: 0;
}

.SearchDropdownResults .result {

    display: flex;
    align-items: center;
    margin: 5px 0;

    h5 {
        @extend .h8;

        color: $blue;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    img {
        margin-right: 15px;
    }

}

.SearchDropdownResults .result:hover {

    h5 {
        color: $gold;
        font-weight: bold;
    }

}

.SearchDropdownResults .see-all {

    @extend .h9;

    color: $blue;
    margin-top: $header-dropdown-padding * 0.5;
    margin-left: 30px;

    &:hover {
        color: $gold;
    }

}






.App.theme-blue {
    .SearchDropdownResults .result h5,
    .SearchDropdownResults .see-all {
        color: $blue;
    }
}

.App.theme-grey {
    .SearchDropdownResults .result h5,
    .SearchDropdownResults .see-all {
        color: $grey;
    }
}

.App.theme-red {
    .SearchDropdownResults .result h5,
    .SearchDropdownResults .see-all {
        color: $red;
    }
}

