@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.ArrowIcon { fill: $cream; }
.ArrowIcon.blue { fill: $blue; }
.ArrowIcon.cream { fill: $cream; }
.ArrowIcon.gold { fill: $gold; }

.ArrowIcon.up { transform: rotate(0deg); }
.ArrowIcon.down { transform: rotate(-180deg); }
.ArrowIcon.left { transform: rotate(-90deg); }
.ArrowIcon.right { transform: rotate(90deg); }
