@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.HeaderMenu {

    display: flex;
    justify-content: center;

}
