@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/mediaqueries.scss';
@import '../../styles/layout.scss';

.AdditionalResources {

    @extend .margin-top;
    @extend .padding-sides;

    display: flex;
    flex-wrap: wrap;

}

.AdditionalResources .wrapper {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    @include tablet-less {
        flex-direction: column;
    }

}

.AdditionalResources .Info {

    width: 33%;
    margin-top: $margin-top;

    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    @include tablet-less {
        width: 100%;

        &:nth-child(2) {
            margin-top: $margin-top-mobile;
        }
    }

}

.AdditionalResources .Info.no-header {
    padding-top: 30px;

    @include tablet-less {
        padding-top: 0;
    }
}

.AdditionalResources .Info h2 {
    @extend .h9;
    color: $blue;
}

.AdditionalResources .Info p,
.AdditionalResources .Info span {
    @extend .body;
    color: $blue;
}

.AdditionalResources .Info a {
    color: $blue;
}





.App.theme-blue {
    .AdditionalResources .Info h2, .AdditionalResources .Info p,
    .AdditionalResources .Info span, .AdditionalResources .Info a {
        color: $blue;
    }
}

.App.theme-grey {
    .AdditionalResources .Info h2, .AdditionalResources .Info p,
    .AdditionalResources .Info span, .AdditionalResources .Info a {
        color: $grey;
    }
}

.App.theme-red {
    .AdditionalResources .Info h2, .AdditionalResources .Info p,
    .AdditionalResources .Info span, .AdditionalResources .Info a {
        color: $red;
    }
}
