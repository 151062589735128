@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.ListItOut {

    @extend .padding-sides;
    @extend .margin-top;

}

.ListItOut h5 {
    @extend .h5;
}

.ListItOut .list-item {

    padding: 10px 0 0 0;
    // border-bottom: 1px solid $gold;
    transition: 0.3s ease-in-out;

    h6 {
        @extend .h9;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }

    h6 span svg {
        width: 20px;
        height: 20px;
        // margin-left: 30px;        
    }

    .DangerousCopy p {
        color: $gold;
    }

}

.ListItOut .list-item-container {
    margin: $paragraph-top 0 $paragraph-top 0;
}
