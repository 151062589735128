@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.ShowMoreSection {
    @extend .margin-top;
}

.ShowMoreSection .blocks {

    display: flex;
    justify-content: space-between;

    margin-top: $padding-top;

    @include mobile {
        margin-top: $padding-top-mobile;
    }

}

.ShowMoreSection .blocks .blocks-column {

    display: flex;
    flex-direction: column;

    width: 45%;

    @include mobile {
        width: 100%;
        padding: 0 $padding-sides-mobile;
        margin-bottom: 0;
    }

}

.ShowMoreSection .blocks .blocks-column .ShowMoreBlock {
    margin-bottom: $padding-top * 2;

    @include mobile {
        margin-bottom: $padding-top;
    }
}
