@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.DangerousCopy p,
.DangerousCopy ul,
.DangerousCopy ol {

    @extend .body;
    margin-top: $paragraph-top;

}

.DangerousCopy strong,
.DangerousCopy b span,
.DangerousCopy span b {
    font-family: 'TTNorms-ExtraBold', sans-serif;
}

.DangerousCopy ol {

    list-style-type: decimal;
    margin-left: 45px;

}

.DangerousCopy ul li {
    &:before {
        content: '+';
        margin-right: 8px;
    }
}

.DangerousCopy h1, .DangerousCopy h2,
.DangerousCopy h3, .DangerousCopy h4,
.DangerousCopy h5, .DangerousCopy h6 {

    @extend .h5;

    color: $blue;
    font-size: 35px;
    line-height: 41px;
    padding-top: $padding-top;

}

.DangerousCopy p {

    @extend .body;

    color: $blue;
    margin-top: $paragraph-top;

    @include mobile-less {
        &:first-of-type {
            margin-top: 40px;
        }
    }

}

.DangerousCopy p a {

    color: $blue;
    font-family: 'TTNorms-ExtraBold', sans-serif;
    transition: color $anim-duration ease-in-out;

    &:hover {
        color: $gold;
    }

}





.App.theme-blue {
    .DangerousCopy h1, .DangerousCopy h2,
    .DangerousCopy h3, .DangerousCopy h4,
    .DangerousCopy h5, .DangerousCopy h6,
    .DangerousCopy p, .DangerousCopy p a {
        color: $blue;
    }
}

.App.theme-grey {
    .DangerousCopy h1, .DangerousCopy h2,
    .DangerousCopy h3, .DangerousCopy h4,
    .DangerousCopy h5, .DangerousCopy h6,
    .DangerousCopy p, .DangerousCopy p a {
        color: $grey;
    }
}

.App.theme-red {
    .DangerousCopy h1, .DangerousCopy h2,
    .DangerousCopy h3, .DangerousCopy h4,
    .DangerousCopy h5, .DangerousCopy h6,
    .DangerousCopy p, .DangerousCopy p a {
        color: $red;
    }
}
