@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.ShowLinksSection {

    @extend .margin-top;

    position: relative;
    padding-top: $margin-top * 2;
    padding-bottom: $margin-top * 2;

    box-shadow: $floating-shadow-top;

    @include mobile-less {
        padding-top: $margin-top-mobile;
        padding-bottom: $margin-top-mobile;
    }

}

.ShowLinksSection .show-links-section-container {
    @extend .padding-sides;
}

.ShowLinksSection .show-links-section-container .wrapper .links-container {

    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    flex-wrap: wrap;

}

.ShowLinksSection .ShowLink {

    width: 45%;
    border-bottom: 1px solid $gold;
    margin-top: $margin-top;
    padding: $paragraph-top 0;

    @include mobile {
        width: 100%;
        margin-top: $margin-top-mobile;

        &:first-of-type {
            margin-top: 0;
        }
    }

}

.ShowLinksSection .ShowLink h5 {

    @extend .h5;
    font-size: 35px;

}

.ShowLinksSection .ShowLink p {

    @extend .body;
    color: $gold;
    margin-bottom: $margin-top;

    @include mobile {
        margin-bottom: $margin-top-mobile;
    }

}
