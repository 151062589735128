@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.NewsSection {

    @extend .margin-top;
    @extend .padding-sides;

}

.NewsSection .wrapper {

    & > h5 {
        @extend .h5;
    }

    & > .DangerousCopy {
        margin-bottom: $margin-top;
    }

}

.NewsSection .previews:not(.has_pagination) > div:last-of-type,
.NewsSection .previews.has_pagination > div:nth-last-of-type(2) {
    border-bottom: none;
}

.news_section:first-child {
    margin-top: $margin-top;

    @include mobile {
        margin-top: 0;
    }
}

@include mobile {

    .NewsSection {
        padding-left: 0;
        padding-right: 0;
    }

    .NewsSection .wrapper {

        & > h5,
        & > .DangerousCopy,
        .previews > a > button,
        .previews .NewsPagination {
            margin-left: $padding-sides-mobile;
            margin-right: $padding-sides-mobile;
        }

    }

}
