@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';

.Radio {

    position: relative;
    cursor: default;

    span {

        @extend .h8;

        display: flex;
        align-items: center;
        color: $blue;

        svg {
            fill: none;
            stroke: $blue;
            stroke-width: 1px;

            margin-right: 5px;
        }

    }

    span:nth-child(2) {

        @extend .h9;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        white-space: nowrap;

        pointer-events: none;
        opacity: 0;

        color: $gold;

        svg {
            fill: $gold;
            stroke: $gold;
            stroke-width: 1px;
        }

    }

}

.Radio.selected {

    span:nth-child(1) { opacity: 0; }
    span:nth-child(2) { opacity: 1; }

}
