.listItOutItem {
    overflow: hidden;
}

h6 span {
    @media (max-width: 500px) {
        margin-left: 30px;
    }
}

h6 span svg {
    transition: 0.2s ease-in-out;
}

h6 span.down svg {    
    transform: rotate(0deg);
}

h6 span.up svg {
    transform: rotate(180deg);
}

.itemCopy {
    transition: 0.2s ease-in-out;
}

.itemCopy.collapsed {
    opacity: 0;
    // display: none;
    height: 0px;
    transition: 0.2s ease-in-out;
    padding-bottom: 0;
}

.itemCopy.expanded {
    display: block;
    height: auto;
    opacity: 1;
    transition: 0.2s ease-in-out;
    padding: 0;
    margin: 0;
    padding-bottom: 25px;    
}