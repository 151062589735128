@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

div.PostGrid {
    display: flex;
    flex-wrap: wrap;
}

div.PostGridPagination {

    display: flex;
    justify-content: center;

    margin-top: $padding-top * 1.5;

    @include mobile {
        margin-top: $padding-top;
    }

}

div.PostGridPagination .screen-reader-text {
    display: none;
}

div.PostGridPagination div.nav-links {
    font-family: 'TTNorms-Regular', sans-serif;
    font-size: 18px;
    font-weight: 100;
    line-height: 18px;
    letter-spacing: 0;
    color: $gold;
}

div.PostGridPagination div.nav-links a {
    color: $gold;
}

div.PostGridPagination div.nav-links span.current {
    font-family: 'TTNorms-ExtraBold', sans-serif;
    font-weight: 900;
}

div.PostGridPagination div.nav-links > * {
    margin: 0 2px;
}

div.PostGridPagination div.nav-links a.next {
    margin-left: 10px;
}

div.PostGridPagination div.nav-links a.prev {
    margin-right: 10px;
}
