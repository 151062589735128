@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

div.PostThumbnail {

    position: relative;

    width: calc(100% / 3);
    height: 0;
    padding-top: calc(100% / 3 * 1.3);
    margin-bottom: 5%;

    @include tablet-less {
        width: 50%;
        padding-top: calc(50% * 1.3);
    }

    @include mobile-less {
        width: 100%;
        padding-top: calc(100% * 1.3);
        margin-bottom: 0;
    }

    @supports (-ms-ime-align:auto) {

        height: calc((100vw - #{$padding-sides-vw} * 2) / 3 * 1.3);
        padding-top: 0;
        margin-bottom: 5vw;

        @media (min-width: 1422px) {
            height: calc(1166px / 3 * 1.3);
            margin-bottom: 68px;
        }

        @include desktop {
            height: calc((100vw - #{$padding-sides-mobile-vw} * 2) / 3 * 1.3);
        }

        @include tablet-less {
            height: calc((50vw - #{$padding-sides-mobile-vw} * 2) * 1.3);
        }

        @include mobile-less {
            height: calc(100vw * 1.3);
            margin-bottom: 0;
        }

    }

}

div.PostThumbnail > a.desktop {
    @include mobile-less {
        display: none;
    }
}

div.PostThumbnail > div.mobile {

    display: none;
    color: $cream;

    @include mobile-less {
        display: block;
    }

}

div.PostThumbnail:nth-last-child(1),
div.PostThumbnail:nth-last-child(2),
div.PostThumbnail:nth-last-child(3) {
    margin-bottom: 0;
}

div.PostThumbnail:nth-last-child(3) {

    @include tablet-less {
        margin-bottom: 5%;
    }

    @include mobile-less {
        margin-bottom: 0;
    }

}

div.PostThumbnail:nth-last-child(2) {
    @include mobile-less {
        margin-bottom: 0;
    }
}

div.PostThumbnail.cream {

    background-color: $cream;

    @include mobile-less {

        margin: 10% 0;

        &:last-child {
            margin: 10% 0 0;
        }

    }

}

div.PostThumbnail.cream + div.PostThumbnail.cream {
    @include mobile-less {

        margin: calc(-10% - 1px) 0 10%;

        &:last-child {
            margin: calc(-10% - 1px) 0 0;
        }

    }
}

div.PostThumbnail.blue {
    background-color: $blue;
}

div.PostThumbnail[data-style="image"] {
    background-size: cover;
    background-position: center center;
}

div.PostThumbnail[data-style="quote"],
div.PostThumbnail[data-style="subscribe"] {
    background-color: $red;
}

div.PostThumbnail[data-style="subscribe"],
div.PostThumbnail[data-style="image"] a,
div.PostThumbnail[data-style="quote"] a,
div.PostThumbnail[data-style="color"].blue a,
div.PostThumbnail[data-style="color"].blue > div.mobile {
    color: $cream;
}

div.PostThumbnail[data-style="color"] a,
div.PostThumbnail[data-style="color"] > div.mobile {
    color: $red;
}

div.PostThumbnail[data-style="color"]:before,
div.PostThumbnail[data-style="color"]:after {

    content: '';

    position: absolute;
    height: 1px;

    background-color: $blue;

}

div.PostThumbnail[data-style="color"]:nth-child(3n + 1) {

    &:before,
    &:after {
        left: 0;
        width: 95%;
    }

    @include tablet-less {
        &:before,
        &:after {
            left: 0;
            width: 100%;
        }
    }

}

div.PostThumbnail[data-style="color"]:nth-child(3n + 2) {

    &:before,
    &:after {
        left: 5%;
        width: 90%;
    }

    @include tablet-less {
        &:before,
        &:after {
            left: 0;
            width: 100%;
        }
    }

}

div.PostThumbnail[data-style="color"]:nth-child(3n + 3) {

    &:before,
    &:after {
        left: 5%;
        width: 95%;
    }

    @include tablet-less {
        &:before,
        &:after {
            left: 0;
            width: 100%;
        }
    }

}

div.PostThumbnail[data-style="color"].cream + div.PostThumbnail[data-style="color"].cream:nth-child(3n + 2) {

    &:before,
    &:after {
        left: 0;
        width: 95%;
    }

    @include tablet-less {
        &:before,
        &:after {
            left: 0;
            width: 100%;
        }
    }

}

div.PostThumbnail[data-style="color"].cream + div.PostThumbnail[data-style="color"].cream:nth-child(3n + 3) {

    &:before,
    &:after {
        left: 0;
        width: 100%;
    }

    @include tablet-less {
        &:before,
        &:after {
            left: 0;
            width: 100%;
        }
    }

}

div.PostThumbnail[data-style="color"]:nth-child(odd) {
    @include tablet-less {
        &:before,
        &:after {
            left: 0 !important;
            width: 95% !important;
        }
    }
}

div.PostThumbnail[data-style="color"]:nth-child(even) {
    @include tablet-less {
        &:before,
        &:after {
            left: 5% !important;
            width: 95% !important;
        }
    }
}

div.PostThumbnail[data-style="color"].cream + div.PostThumbnail[data-style="color"]:nth-child(even) {
    @include tablet-less {
        &:before,
        &:after {
            left: 0 !important;
            width: 100% !important;
        }
    }
}

div.PostThumbnail[data-style="color"].cream:nth-child(odd),
div.PostThumbnail[data-style="color"].cream:nth-child(even),
div.PostThumbnail[data-style="color"].cream + div.PostThumbnail[data-style="color"]:nth-child(even) {
    @include mobile-less {
        &:before,
        &:after {
            left: 5% !important;
            width: 90% !important;
        }
    }
}

div.PostThumbnail[data-style="color"]:before {
    top: 0;
}

div.PostThumbnail[data-style="color"]:after {
    bottom: 0;
}

div.PostThumbnail div.inner-content {

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 10% $padding-sides-mobile * 1.5 7.5%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @include mobile-less {
        padding: 10% $padding-sides-mobile 7.5%;
    }

}

div.PostThumbnail div.inner-content div.content-wrapper {
    width: 100%;
}

div.PostThumbnail[data-style="subscribe"] div.inner-content {

    padding: 20% $padding-sides-mobile * 1.5 7.5%;

    @include mobile-less {
        padding: 20% $padding-sides-mobile 7.5%;
    }

}

div.PostThumbnail[data-style="color"].cream div.inner-content {

    padding: 10% $padding-sides-mobile * 1.5 7.5%;

    @include mobile-less {
        padding: 10% $padding-sides-mobile 7.5%;
    }

}

div.PostThumbnail div.inner-content div.date,
div.PostThumbnail div.inner-content h4 {

    @extend .h7;

    font-size: 13px;
    line-height: 13px;
    font-weight: 600;

}

div.PostThumbnail div.inner-content h3,
div.PostThumbnail div.inner-content h5.quote {
    @extend .h6;
    line-height: 35px;
}

div.PostThumbnail div.inner-content h3 {

    font-size: 35px;
    margin-bottom: 10px;

    @include desktop-micro {
        font-size: 25px;
        line-height: 27px;
    }

    @include tablet-less {
        font-size: 35px;
        line-height: 35px;
    }

}

div.PostThumbnail div.inner-content h4 {
    margin-bottom: 30px;
}

div.PostThumbnail div.inner-content h5.category {

    @extend .h8;

    font-size: 20px;
    line-height: 23px;

    margin-top: 10px;

}

div.PostThumbnail.cream div.inner-content h5.category,
div.PostThumbnail[data-style="quote"] div.inner-content h5.category {
    color: $blue;
}

div.PostThumbnail[data-style="subscribe"] div.inner-content h4 {

    @extend .h8;

    font-size: 20px;
    line-height: 23px;

    width: 100%;
    margin-bottom: 0;

}

div.PostThumbnail[data-style="subscribe"] div.inner-content h3 {
    width: 100%;
}

div.PostThumbnail div.inner-content h5.quote {
    font-size: 100px;
    margin-bottom: -25px;
}

div.PostThumbnail div.inner-content p {

    @extend .h7;

    font-size: 16px;
    line-height: 23px;

    margin-bottom: 30px;

}

div.PostThumbnail.cream a div.inner-content p,
div.PostThumbnail.cream > div.mobile div.inner-content p {
    color: $blue;
}

div.PostThumbnail div.inner-content div.read-more {

    @extend .h9;
    line-height: 13px;

    @include mobile-less {
        display: none;
    }

}

div.PostThumbnail.cream a div.inner-content div.read-more,
div.PostThumbnail[data-style="quote"] a div.inner-content div.read-more {
    color: $blue;
}

div.PostThumbnail[data-style="subscribe"] div.inner-content button {

    width: 100%;
    max-width: 225px;

    border-color: $cream;
    background-color: $cream;
    color: $red;

}

div.PostThumbnail[data-style="subscribe"] div.inner-content button:hover {
    border-color: $cream;
    background-color: $red;
    color: $cream;
}

div.PostThumbnail:not([data-style="subscribe"]) button {

    display: none;

    @include mobile-less {
        display: block;
    }

}

div.PostThumbnail[data-style="image"] button,
div.PostThumbnail[data-style="color"].blue button {
    border-color: $cream;
    color: $cream;
}

div.PostThumbnail[data-style="image"] button:active,
div.PostThumbnail[data-style="image"] button:hover,
div.PostThumbnail[data-style="color"].blue button:active,
div.PostThumbnail[data-style="color"].blue button:hover {
    background-color: $cream;
    color: $red;
}

div.PostThumbnail[data-style="color"].cream button,
div.PostThumbnail[data-style="quote"] button {
    border-color: $blue;
    color: $blue;
}

div.PostThumbnail[data-style="color"].cream button:active,
div.PostThumbnail[data-style="color"].cream button:hover,
div.PostThumbnail[data-style="quote"] button:active,
div.PostThumbnail[data-style="quote"] button:hover {
    background-color: $blue;
    color: $cream;
}

div.PostThumbnail a {
    text-decoration: none;
}
