@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.ShowMoreList {

    @extend .padding-sides;
    @extend .margin-top;

    margin-top: 0;

}

.ShowMoreList .blocks {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin-top: $padding-top;

}

.ShowMoreList .block-item {

    margin-bottom: $padding-top;
    width: 45%;

    h5 {
        @extend .h2;

        position: relative;
        margin-bottom: 18px;

        font-size: 31px;
        list-style-type: decimal;
        list-style-position: outside;

        span {
            position: absolute;
            left: -40px;
        }
    }

    @include mobile {
        width: 100%;

        h5 span {
            position: relative;
            left: 0;
            margin-right: $paragraph-top * 0.5;
        }
    }

}
