@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.FloatingSection {

    @extend .margin-top;
    @extend .padding-sides;

    width: calc(100% - #{$padding-sides} * 2);
    min-height: calc(100vw / 3.5);
    margin-left: $padding-sides;
    margin-right: $padding-sides;
    padding-top: $margin-top * 2;
    padding-bottom: $margin-top * 2;

    display: flex;
    align-items: center;

    text-align: center;

    background-color: $cream;
    z-index: 5;

    &.full-width {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    &.full-width.shadowed {
        box-shadow: $floating-shadow;
    }

    &.full-width:not(.shadowed) {
        margin: 0;
    }

    @include mobile-less {
        margin-left: 0;
        margin-right: 0;
    }

    @include ie11 {
        min-height: 0;
    }

}

.FloatingSection:not(.shadowed) {
    background-color: transparent;
}

.FloatingSection.shadowed {

    box-shadow: $floating-shadow-bottom;

    @include mobile-less {
        h3 {
            color: $blue;
            text-shadow: none;
        }
    }

}

.FloatingSection div.wrapper {
    max-width: 768px;
}

.FloatingSection h3 {

    @extend .h3;

    color: transparent;
    text-stroke: 2px $blue;
    -webkit-text-stroke: 2px $blue;

    @include ie11 {
        color: $blue;
    }

}

.FloatingSection p {
    @extend .body;
    margin-top: $paragraph-top;
}

.FloatingSection button {
    margin-top: $margin-top-button;
}
