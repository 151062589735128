@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.NewsPagination {

    @extend .h8;

    display: flex;
    justify-content: center;
    margin: $padding-top 0;
    color: $gold;

    & > * {
        padding: 0 5px;
    }

    .number.active,
    .number:hover {
        color: $blue;
        cursor: pointer;
    }

    .prev,
    .next {
        display: flex;
        align-items: center;

        &:hover {
            color: $blue;
            cursor: pointer;

            svg {
                fill: $blue;
            }
        }
    }

    .prev span { margin: 0 10px; }
    .next span { margin: 0 10px; }

    @include mobile {
        justify-content: flex-start;
        margin: 0;
    }

}





.App.theme-blue {
    .NewsPagination .number.active,
    .NewsPagination .number:hover {
        color: $blue;
    }

    .NewsPagination .prev:hover,
    .NewsPagination .next:hover {
        color: $blue;

        svg {
            fill: $blue;
        }
    }
}

.App.theme-grey {
    .NewsPagination .number.active,
    .NewsPagination .number:hover {
        color: $grey;
    }

    .NewsPagination .prev:hover,
    .NewsPagination .next:hover {
        color: $grey;

        svg {
            fill: $grey;
        }
    }
}

.App.theme-red {
    .NewsPagination .number.active,
    .NewsPagination .number:hover {
        color: $red;
    }

    .NewsPagination .prev:hover,
    .NewsPagination .next:hover {
        color: $red;

        svg {
            fill: $red;
        }
    }
}
