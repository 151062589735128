@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/mediaqueries.scss';
@import '../../styles/layout.scss';

.HeroCarousel div.carousel-wrapper {
    position: relative;
    overflow-x: hidden;
}

.HeroCarousel div.arrows {

    @extend .padding-sides;

    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 50px;
    margin-bottom: $padding-top;

    pointer-events: none;

    @include mobile {
        display: none;
    }

}

.HeroCarousel div.arrows div.arrow {

    transition: transform 0.2s ease-in-out;
    pointer-events: auto;
    cursor: pointer;

    &:hover {
        transform: scale(1.2);
    }

}

.HeroCarousel div.arrows img {
    height: 100%;
}

.HeroCarousel .Slide {

    @extend .padding-sides;

    position: relative;

    width: 100%;
    height: 480px;
    padding-top: $padding-top;
    padding-bottom: $padding-top;

    background-size: cover;
    background-position: center center;

    display: flex;
    align-items: center;

    text-align: center;

    @include phone-micro {
        height: 420px;
    }

}

.HeroCarousel .Slide h2,
.HeroCarousel .Slide h6 {
    color: $cream;
}

.HeroCarousel .Slide h2 {
    @extend .h1;
}

.HeroCarousel .Slide h6 {
    @extend .h7;
    margin: 20px 0 0;
}

.HeroCarousel .Slide.button-slide h6 {
    margin-bottom: $margin-top-button;
}

.HeroCarousel .Slide button {


    position: absolute;
    bottom: $padding-top;
    left: 50%;

    transform: translateX(-50%);

}

.HeroCarousel div.titles {

    display: flex;
    justify-content: center;

    padding: $padding-top / 2 0;

    @include tablet-less {
        display: none;
    }

}

.HeroCarousel div.titles h5 {

    @extend .h9;

    position: relative;

    padding: 0 80px;
    text-align: center;

    transition: color $anim-duration ease-in-out;
    cursor: pointer;

}

.HeroCarousel div.titles h5:hover,
.HeroCarousel div.titles h5.is-selected {
    color: $gold;
}

.HeroCarousel div.titles h5:after {

    content: '';

    position: absolute;
    top: 50%;
    left: 100%;

    width: 30px;
    height: 2px;

    background-color: $blue;

    transform: translateX(-50%);

}

.HeroCarousel div.titles h5:last-of-type:after {
    display: none;
}

/* @TODO Merge with Hero Image. */

.HeroCarousel .flickity-enabled {
    outline: none;
}

.HeroCarousel .flickity-button {
    display: none;
}

.HeroCarousel .flickity-page-dots {

    position: absolute;
    bottom: $padding-top / 2;
    left: 0;
    z-index: 5;

    width: 100%;

    display: none;
    justify-content: center;

    transform: translateY(50%);

    @include mobile {
        display: flex;
    }

}

.HeroCarousel .flickity-page-dots .dot {

    width: 8px;
    height: 8px;
    margin: 0 8px;

    border-radius: 50%;

    background-color: $cream;
    opacity: .4;

    transition: opacity $anim-duration ease-in-out;

}

.HeroCarousel .flickity-page-dots .dot.is-selected {
    opacity: 1;
}





.App.theme-blue {
    .HeroCarousel div.titles h5 {
        color: $blue;
    }
    .HeroCarousel div.titles h5:after {
        background-color: $blue;
    }
}

.App.theme-grey {
    .HeroCarousel div.titles h5 {
        color: $grey;
    }
    .HeroCarousel div.titles h5:after {
        background-color: $grey;
    }
}

.App.theme-red {
    .HeroCarousel div.titles h5 {
        color: $red;
    }
    .HeroCarousel div.titles h5:after {
        background-color: $red;
    }
}
