@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/mediaqueries.scss';
@import '../../styles/layout.scss';

.VillainImage {

    @extend .padding-sides;
    @extend .margin-top;

    @include mobile {
        padding: 0;
        margin: 0;
    }

}

.VillainImage div.image {

    min-height: 420px;
    padding: $padding-top-extra $padding-sides * 1.5 $padding-top * 1.5;

    background-size: cover;
    background-position: center center;

    color: $blue;
    text-align: center;

    display: flex;
    align-items: center;

    @include mobile {
        padding: $padding-top-mobile $padding-sides-mobile;
    }

    @include tablet {
        min-height: 0;
    }

    @include ie11 {
        min-height: 0;
    }

}

.VillainImage.blue div.image {

    background: $blue !important;
    color: $cream !important;

    h3 {
        color: $cream !important;
    }

}

.VillainImage.cream div.image {

    background: $cream !important;
    color: $blue !important;

    h3 {
        color: $blue !important;
    }

}

.VillainImage.gold div.image {

    background: $gold !important;
    color: $cream !important;

    h3 {
        color: $cream !important;
    }

}

.VillainImage.grey div.image {

    background: $grey !important;
    color: $cream !important;

    h3 {
        color: $cream !important;
    }

}

.VillainImage.red div.image {

    background: $red !important;
    color: $cream !important;

    h3 {
        color: $cream !important;
    }

}

.VillainImage .wrapper {
    width: 100%;
}

.VillainImage div.image div.wrapper {
    max-width: 768px;
}

.VillainImage div.image h3 {

    @extend .h3;

    color: $blue;

}

.VillainImage div.image p {

    @extend .body;
    margin-top: $paragraph-top;

}

.VillainImage div.image .buttons {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: $margin-top-button * 2;

    button {
        margin: 0 $margin-top-button * 0.25 $paragraph-top;
    }

    @include mobile {
        flex-direction: column;
        margin-top: $margin-top-button;

        button {
            margin: $margin-top-button * 0.25 0;
        }
    }

}

.VillainImage.has-image {

    div.image {
        color: $cream !important;
    }

    div.image h3 {
        color: transparent;
        text-stroke: 2px $cream;
        -webkit-text-stroke: 2px $cream;

        @include ie11 {
            color: $cream;
        }
    }

}

// Special Rules
div.floating_section + div.villain_image .VillainImage,
div.aligned_copy_list + div.villain_image .VillainImage {
    margin-top: -$margin-top;

    @include mobile-less {
        margin-top: -$margin-top-mobile;
    }
}





.App.theme-blue {
    .VillainImage div.image, .VillainImage div.image h3 {
        color: $blue;
    }
}

.App.theme-grey {
    .VillainImage div.image,
    .VillainImage div.image h3 {
        color: $grey;
    }
}

.App.theme-red {
    .VillainImage div.image,
    .VillainImage div.image h3 {
        color: $red;
    }
}
