@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.HeaderMobileMenuItem {

    position: relative;
    padding: 0 $padding-sides-mobile;
    margin: 0 auto;

    opacity: 0.33;

}

.HeaderMobileMenuItem h5 {

    @extend .h9;

    color: $cream;
    display: flex;
    justify-content: space-between;
    align-items: center;

    line-height: 50px;
}

.HeaderMobileMenuItem.expanded {

    opacity: 1;
    border-top: 1px solid $gold;
    border-bottom: 1px solid $gold;

}
