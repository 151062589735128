@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/mediaqueries.scss';
@import '../../styles/layout.scss';

.QuickInfo {

    @extend .margin-top;

    display: flex;
    flex-wrap: wrap;

    @include mobile {
        padding: 0;
        margin: 0;
    }

}

.QuickInfo.padded {

    @extend .padding-sides;

    @include mobile {
        padding: 0;
        margin: 0;
    }

}

.QuickInfo .Info {

    width: 50%;
    padding: 0 $padding-sides-mobile;
    margin-top: $margin-top;

    &:nth-child(1),
    &:nth-child(2) {
        margin-top: 0;
    }

    @include tablet {

        padding: 0;

        &:nth-child(odd) {
            padding-right: $padding-sides-mobile;
        }

        &:nth-child(even) {
            padding-left: $padding-sides-mobile;
        }

    }

    @include tablet-less {

        width: 100%;
        margin-top: $margin-top-mobile;

        &:nth-child(odd),
        &:nth-child(even) {
            padding: 0;
        }

        &:nth-child(1) {
            margin-top: 0;
        }

        &:nth-child(2) {
            margin-top: $margin-top-mobile;
        }

    }

    @include mobile {

        &:nth-child(odd),
        &:nth-child(even) {
            margin: $padding-top 0 0;
            padding: 0 $padding-sides;
        }

        &:last-child {
            margin-bottom: $padding-top;
        }

    }

}

.QuickInfo .Info.blue,
.QuickInfo .Info.cream,
.QuickInfo .Info.gold,
.QuickInfo .Info.grey,
.QuickInfo .Info.red {

    &:not(.mobile-only) {
        color: $cream;
        padding: $padding-top $padding-sides;
    }

    @include mobile {
        color: $cream;
        margin: 0;
        padding: $padding-top $padding-sides;
    }

}

.QuickInfo .Info.blue {
    &:not(.mobile-only) { background-color: $blue; }
    @include mobile { background-color: $blue; }
}

.QuickInfo .Info.cream {
    &:not(.mobile-only) { background-color: $cream; color: $blue; }
    @include mobile { background-color: $cream; color: $blue; }
}

.QuickInfo .Info.gold {
    &:not(.mobile-only) { background-color: $gold; }
    @include mobile { background-color: $gold; }
}

.QuickInfo .Info.grey {
    &:not(.mobile-only) { background-color: $grey; }
    @include mobile { background-color: $grey; }
}

.QuickInfo .Info.red {
    &:not(.mobile-only) { background-color: $red; }
    @include mobile { background-color: $red; }
}

.QuickInfo .Info .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    height: 100%;

    text-align: center;
}

.QuickInfo .Info h2 {
    @extend .h4;
    text-transform: uppercase;
}

.QuickInfo .Info p {
    @extend .body;
    margin-top: $paragraph-top;
    flex-grow: 1;
    max-width: 100%;
}

.QuickInfo .Info button {
    margin-top: $margin-top-button;
}

.QuickInfo .Info a {
    margin-top: $margin-top-button;

    button {
        margin-top: 0;
    }
}
