@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.BlockBackground {

    position: absolute;
    bottom: -$margin-top;
    left: 50%;
    z-index: -1;

    width: 100%;
    opacity: .1;

    transform: translateX(-50%);

    @include mobile-less {
        bottom: -$margin-top-mobile;
    }

}
