@import '../../styles/variables.scss';
@import '../../styles/typography.scss';

.ShowMoreToggle {

    @extend .h9;

    display: flex;
    align-items: center;

    color: $gold;
    cursor: default;

}

.ShowMoreToggle span {
    margin-right: 5px;
}

.ShowMoreToggle svg {
    fill: $gold;
    width: 7px;
}

.ShowMoreToggle.blue {
    color: $blue;
    svg { fill: $blue; }
}

.ShowMoreToggle.cream {
    color: $cream;
    svg { fill: $cream; }
}

.ShowMoreToggle.gold {
    color: $gold;
    svg { fill: $gold; }
}

.ShowMoreToggle.red {
    color: $red;
    svg { fill: $red; }
}

.ShowMoreToggle.white {
    color: $white;
    svg { fill: $white; }
}
