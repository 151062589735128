@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.LinkExpandMenu {

    @extend .footer;

    color: $cream;
    margin: 0 $header-item-padding;
    padding-bottom: $padding-top-mobile * 0.5;

    @include mobile-less {
        margin: 0 25px;
    }

}

.LinkExpandMenu .MenuLink {

    color: $cream;
    line-height: 50px;

    &.anchor div::before {
        content: '>';
        margin-right: 10px;
        line-height: 1;
    }

    &:hover {
        color: $gold;
    }

    & > div {
        animation: 0.5s ease-in-out 1 menu-item-in;
        animation-fill-mode: forwards;

        opacity: 0;
    }

}

@for $i from 1 through 100 {
    .LinkExpandMenu .MenuLink:nth-child(#{$i}) div {
        animation-delay: $i * 0.05s;
    }
}

@keyframes menu-item-in {
    from {
        opacity: 0;
        transform: translateX(-15px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
