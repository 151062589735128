@import '../../styles/variables.scss';
@import '../../styles/mediaqueries.scss';
@import '../../styles/layout.scss';

.App {

    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    color: $blue;

    & > * {
        flex-shrink: 0;
    }

}

div.wrapper {

    @extend .wrapper-width;

}

:global {

    .hideOverflow,
    .hideOverflow #root {
        overflow: hidden;
    }

}





.App.theme-blue {
    color: $blue;
}

.App.theme-grey {
    color: $grey;
}

.App.theme-red {
    color: $red;
}
