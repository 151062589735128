@import './variables.scss';
@import './typography.scss';
@import './layout.scss';

.form-container {

    margin: $paragraph-top 0;

    .form-error {
        @extend .h8;
        color: $red;
    }

    .form-group {
        @extend .form-group;
    }

    .form-group.col-2 {
        @extend .form-group.col-2;
    }

    .form-input {
        @extend .form-input;
        @extend .form-input.bordered;

        margin-top: $paragraph-top;
    }

    .form-note {
        @extend .h8;
        color: $gold;
        margin-top: $paragraph-top * 3;

        @include mobile {
            margin-top: $paragraph-top;
        }
    }

    .form-submit {
        display: inline-block;
        margin-top: $paragraph-top;
    }

}

.form-group {

    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 100%;

    &.col-2 {
        .form-input {
            width: 49%;

            @include mobile {
                width: 100%;
            }
        }
    }
}

.form-input {

    position: relative;
    display: flex;
    align-items: center;

    width: 100%;

    svg {
        margin-left: $header-dropdown-padding;

        g {
            stroke: $gold;
        }
    }

    input, .input, textarea {

        @extend .h8;

        color: $blue;
        border: none;
        background: none;

        flex-grow: 1;

        &::placeholder {
            color: $gold;
        }

    }

    input, .input {

        margin: 0;
        padding: $header-dropdown-padding * 0.5 $header-dropdown-padding;
        height: 49px;

    }

    textarea {

        margin: $header-dropdown-padding * 0.5 $header-dropdown-padding;
        height: 200px;
        resize: none;

    }

    .submitter {

        @extend .h9;

        appearance: none;
        background: none;
        border: none;

        justify-self: flex-end;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 49px;
        height: 49px;
        padding-left: 10px;
        padding-right: 10px;

        color: $blue;
        border-left: 2px solid $gold;
        cursor: pointer;

        transition: background 200ms ease-in-out, color 200ms ease-in-out;

        &:hover {
            background: $gold;
            color: $cream;
        }

    }

}

.form-input.bordered {
    border: 2px solid $gold;
}

.form-input.error {

    &.bordered {
        background: $red-20;
        border-color: $red;
    }

    .submitter {
        border-left-color: $red;
    }

}

.form-select {

    cursor: default;

    .input {
        display: flex;
        align-items: center;

        .placeholder {
            color: $gold;
        }
    }

    .submitter span {
        transform: rotate(90deg);
    }

    &.opened .submitter span {
        transform: rotate(270deg);
    }

}

.form-select + .options {

    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%) translateY(-2px);

    max-height: 154px;
    width: 100%;

    background: $cream;
    border: 2px solid $gold;

    box-shadow: $floating-shadow-bottom;
    overflow: auto;
    z-index: 5;

    & > div {

        @extend .body;

        color: $blue;
        padding: $header-dropdown-padding * 0.5 $header-dropdown-padding;

        &:hover {
            color: $gold;
            font-family: 'TTNorms-ExtraBold';
            cursor: pointer;
        }

        @include mobile {
            padding: $header-dropdown-padding;
        }

    }

}
