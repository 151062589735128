@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.HeaderMenuItem {
    position: relative;
}

.HeaderMenuItem h5 {

    @extend .h9;
    color: $gold;

    position: relative;
    padding: 10px $header-item-padding;

    span:nth-child(2) {

        @extend .h9;
        color: $blue;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        white-space: nowrap;

        pointer-events: none;
        opacity: 0;

    }

}

.HeaderMenuItem:hover,
.HeaderMenuItem.active,
.HeaderMenuItem.hovered {

    h5 {
        span:nth-child(1) { opacity: 0; }
        span:nth-child(2) { opacity: 1; }
    }

    .dropdown {
        display: initial;
    }

}

.HeaderMenuItem.collapsed h5 {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 40px;
}

.HeaderMenuItem.collapsed:hover,
.HeaderMenuItem.collapsed.active,
.HeaderMenuItem.collapsed.hovered {
    h5 {
        opacity: 1 !important;
    }
}

.HeaderMenuItem.collapsed:not(.active) h5 {
    opacity: 1;
}





.App.theme-blue {
    .HeaderMenuItem h5 span:nth-child(2) {
        color: $blue;
    }
}

.App.theme-grey {
    .HeaderMenuItem h5 span:nth-child(2) {
        color: $grey;
    }
}

.App.theme-red {
    .HeaderMenuItem h5 span:nth-child(2) {
        color: $red;
    }
}
