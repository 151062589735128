@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.HoverHeader {
    position: relative;
}

.HoverHeader span {

    display: flex;
    align-items: center;

}

.HoverHeader span:nth-child(2) {

    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    white-space: nowrap;

    pointer-events: none;
    opacity: 0;

    svg {
        stroke-width: 2px;
    }

}

.HoverHeader:hover {

    span:nth-child(1) { opacity: 0; }
    span:nth-child(2) { opacity: 1; }

}
