@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.FadModal {

    position: absolute;
    top: 0;
    right: 0;
    padding: $padding-top * 2;

    height: 100%;
    background: $blue;
    opacity: 0.98;

    transform: translateX(100%);
    transition: transform 800ms $ease-in-out-cubic;

    h3 {
        @extend .h3;
        color: $cream;
        margin-bottom: $paragraph-top * 1.5;
    }

    @include mobile {
        padding-left: $padding-sides-mobile;
        padding-right: $padding-sides-mobile;
    }

}

.FadModal.open {
    transform: translateX(0%);
}

.FadModal .FadForm {

    color: $cream;
    margin-right: 10vw;
    max-width: 460px;

    .h8 {
        @extend .body;
    }

    .question .h8 {
        color: $cream;
        margin-bottom: $paragraph-top * 0.25;
    }

    .RadioInput .Radio {
        span {
            font-size: 15px;
        }
    }

    @include mobile {
        .h9 {
            margin-top: $margin-top;
        }
    }

}

.App.theme-blue {
    .FadModal, .FadModal .ModalCloseIcon {
        background: $blue;
    }
}

.App.theme-grey {
    .FadModal, .FadModal .ModalCloseIcon {
        background: $grey;
    }
}

.App.theme-red {
    .FadModal, .FadModal .ModalCloseIcon {
        background: $red;
    }
}
