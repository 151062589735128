@import '../../../../styles/variables.scss';

.TimelineControls {

    display: flex;
    justify-content: space-between;
    align-items: stretch;

    margin-bottom: $padding-top;

}

.TimelineControls .prev,
.TimelineControls .next {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.TimelineControls .prev {
    transform: rotate(180deg);
}

.TimelineControls .markers {

    position: relative;
    display: flex;
    justify-content: space-around;
    margin: 0 $paragraph-top;

    flex-grow: 1;

}

.TimelineControls .markers .marker {

    position: relative;
    flex-grow: 1;

    &:before {
        content: '';

        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        height: 10px;
        transform: translateX(-50%);

        background: $gold;
    }

    &:after {
        content: '';

        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        transform: translateY(-50%);

        background: $gold;
    }

}

.TimelineControls .markers .marker.active {

    &:before {
        width: 3px;
        background: $blue;
    }

    &:after {
        left: $paragraph-top * 0.5;
        width: calc(100% - #{$paragraph-top * 0.5} * 2);
        height: 7px;

        border-radius: 3.5px;
    }
}





.App.theme-blue {
    .TimelineControls .markers .marker.active:before {
        background: $blue;
    }
}

.App.theme-grey {
    .TimelineControls .markers .marker.active:before {
        background: $grey;
    }
}

.App.theme-red {
    .TimelineControls .markers .marker.active:before {
        background: $red;
    }
}
