@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';
@import '../../styles/forms.scss';

.ContactForm {

    @extend .margin-top;
    @extend .padding-sides;

    min-height: 50vh;

    h5 {
        @extend .h5;
    }

    p {
        @extend .body;
        margin-top: $paragraph-top;
    }

}

.ContactForm .wrapper {
    & > p {
        max-width: $paragraph-max-width;
    }
}

.ContactForm .form-container {
    @extend .form-container;
}

.ContactForm .form-reset {
    margin-top: $padding-top;
}
