@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.SearchResultsPreview {
    max-width: $paragraph-max-width;
}

.SearchResultsPreview .result {
    margin-bottom: $margin-top;
}

.SearchResultsPreview a {

    h5 {
        @extend .h9;
        color: $blue;
    }

    .HighlightCopyBlock {

        @extend .body;
        color: $blue;
        margin-top: $paragraph-top * 0.5;

    }

    .learn-more {
        @extend .h8;
        display: none;
    }

    &:hover {
        h5 { color: $gold; }
        .HighlightCopyBlock { color: $gold; }
    }

    @include mobile {

        .learn-more {

            color: $gold;
            font-weight: bold;
            line-height: 1;

            display: flex;
            align-items: center;
            margin-top: $paragraph-top;
            margin-bottom: $margin-top-mobile;

            span {
                margin-right: 8px;
            }

            .ArrowIcon {
                width: 8px;
            }

        }

    }

}





.App.theme-blue {
    .SearchResultsPreview a h5,
    .SearchResultsPreview a .HighlightCopyBlock {
        color: $blue;
    }
}

.App.theme-grey {
    .SearchResultsPreview a h5,
    .SearchResultsPreview a .HighlightCopyBlock {
        color: $grey;
    }
}

.App.theme-red {
    .SearchResultsPreview a h5,
    .SearchResultsPreview a .HighlightCopyBlock {
        color: $red;
    }
}
