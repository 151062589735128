@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/mediaqueries.scss';
@import '../../styles/layout.scss';

.SidekickImage {

    @extend .padding-sides;
    @extend .margin-top;

    @include mobile {
        padding: 0;
        margin: 0;
    }

}

.SidekickImage.has-margins .image {

    @extend .wrapper-width;

}

.SidekickImage div.image {

    min-height: 420px;
    padding: $margin-top * 2 $padding-sides;

    background-size: cover;
    background-position: center center;

    color: $cream;
    text-align: center;

    display: flex;
    align-items: center;

    @include tablet {
        min-height: 0;
    }

    @include ie11 {
        min-height: 0;
    }

}

.SidekickImage div.image div.wrapper {
    max-width: 768px;
}

.SidekickImage div.image h2 {
    @extend .h4;
}

.SidekickImage div.image p {
    @extend .body;
    color: $cream;
    margin-top: $paragraph-top;
}

.SidekickImage.has-image div.image p {
    color: $cream !important;
}

.SidekickImage div.image button {
    margin-top: $margin-top-button;
}

.SidekickImage.blue div.image {
    background: $blue;
    color: $cream;
}
.SidekickImage.cream div.image {
    background: $cream;
    color: $blue;
}
.SidekickImage.gold div.image {
    background: $gold;
    color: $cream;
}
.SidekickImage.grey div.image {
    background: $grey;
    color: $cream;
}
.SidekickImage.red div.image {
    background: $red;
    color: $cream;
}

// Special Rules
div.floating_section + div.sidekick_image .SidekickImage,
div.aligned_copy_list + div.sidekick_image .SidekickImage {
    margin-top: -$margin-top;

    @include mobile-less {
        margin-top: -$margin-top-mobile;
    }
}
