$screen-phone-micro: 320px;
$screen-phone: 480px;
$screen-tablet-portrait-less: 767px;
$screen-tablet-portrait: 768px;
$screen-tablet-landscape-less: 1023px;
$screen-tablet-landscape: 1024px;
$screen-desktop-micro: 1100px;
$screen-desktop: 1280px;

$menu-break: 1099px;

@mixin phone-micro {
    @media (max-width: #{$screen-phone-micro}) {
        @content;
    }
}

@mixin phone {
    @media (max-width: #{$screen-phone}) {
        @content;
    }
}

@mixin mobile-less {
    @media (max-width: #{$screen-tablet-portrait-less}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$screen-tablet-portrait}) {
        @content;
    }
}

@mixin mobile-up {
    @media (min-width: #{$screen-tablet-portrait}) {
        @content;
    }
}

@mixin tablet-less {
    @media (max-width: #{$screen-tablet-landscape-less}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$screen-tablet-landscape}) {
        @content;
    }
}

@mixin tablet-landscape {
    @media (min-width: #{$screen-tablet-portrait}) and (max-width: #{$screen-tablet-landscape})
            and (max-height: #{$screen-tablet-portrait}) {
                @content;
    }
}

@mixin desktop-micro {
    @media (max-width: #{$screen-desktop-micro}) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: #{$screen-desktop}) {
        @content;
    }
}

@mixin ie11 {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

@mixin menu-break {
    @media (max-width: #{$menu-break}) {
        @content;
    }
}
