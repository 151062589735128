@import './variables.scss';

.padding-sides {

    padding-left: $padding-sides;
    padding-right: $padding-sides;

    @include mobile-less {
        padding-left: $padding-sides-mobile;
        padding-right: $padding-sides-mobile;
    }

}

.padding-deep-sides {

    padding-left: $padding-deep-sides;
    padding-right: $padding-deep-sides;

    @include mobile-less {
        padding-left: $padding-deep-sides-mobile;
        padding-right: $padding-deep-sides-mobile;
    }

}

.margin-top {

    margin-top: $margin-top;
    margin-bottom: $margin-top;

    @include mobile-less {
        margin-top: $margin-top-mobile;
        margin-bottom: $margin-top-mobile;
    }

}

.wrapper-width {

    max-width: 1024px;
    margin: 0 auto;

    @include tablet {
        max-width: 768px;
    }

}
