.HeaderLogoBar {

    flex-grow: 1;

    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}

.HeaderLogoBar > a.iron-road-logo {
    margin-top: 33px;

    img {
        max-width: 275px;
    }
}

.HeaderLogoBar.collapsed > a.iron-road-logo {
    margin-top: 20px;

    img {
        max-width: 115px;
    }
}
