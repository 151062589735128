@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';


.HeaderSubmenu {

    display: flex;
    justify-content: center;
    align-items: center;

    background: $blue-93;

}

.HeaderSubmenu .MenuLink {

    @extend .h8;

    color: $cream;
    display: flex;
    align-items: center;
    padding: 10px $header-item-padding;

    & > svg {
        display: none;
    }

    &:hover {
        color: $gold;

        & > svg {
            fill: $gold;
        }
    }

}

.HeaderSubmenu .MenuLink.anchor {
    & > svg {
        display: initial;
        margin-right: 5px;
        height: 1em;
    }
}





.App.theme-blue .HeaderSubmenu {
    background: $blue-93;
}

.App.theme-grey .HeaderSubmenu {
    background: $grey-93;
}

.App.theme-red  .HeaderSubmenu {
    background: $red-93;
}
