@import '../../styles/variables.scss';
@import '../../styles/typography.scss';

.Button {

    appearance: none;
    border: none;
    background: none;
    outline: none;
    border-radius: 0;

    @extend .h10;

    position: relative;

    border: 2px solid;
    width: 225px; /* Not a fan of hardcoding button widths, but they all seem to have the same size. */
    height: 50px;

    transition: color $anim-duration ease-in-out,
        background-color $anim-duration ease-in-out,
        border-color $anim-duration ease-in-out;

    cursor: pointer;

}

.Button span {
    margin-left: 3px; /* Offset the .h10 letter-spacing */
}

.Button > * {
    position: relative;
}

/********/
/* GOLD */
/********/
.Button.gold {
    color: $cream;
    background-color: $gold;
    border-color: $gold;
}

.Button.gold.hollow {
    color: $gold;
    background-color: transparent;
}

.Button.gold:hover,
.Button.gold:active {
    color: $gold;
    background-color: $cream;
    border-color: $cream;
}

.Button.gold.hollow:hover,
.Button.gold.hollow:active {
    color: $cream;
    background-color: $gold;
    border-color: $gold;
}

/*********/
/* CREAM */
/*********/
.Button.cream {
    border-color: $cream;
    color: $blue;
    background-color: $cream;
}

.Button.cream.hollow {
    color: $cream;
    background-color: transparent;
}

.Button.cream:hover,
.Button.cream:active {
    color: $cream;
    background-color: $blue;
    border-color: $blue;
}

.Button.cream.hollow:hover,
.Button.cream.hollow:active {
    color: $blue;
    background-color: $cream;
    border-color: $cream;
}

/*********/
/*  RED  */
/*********/
.Button.red {
    color: $cream;
    background-color: $red;
    border-color: $red;
}

.Button.red.hollow {
    color: $red;
    background-color: transparent;
}

.Button.red:hover,
.Button.red:active {
    color: $red;
    background-color: $cream;
    border-color: $cream;
}

.Button.red.hollow:hover,
.Button.red.hollow:active {
    color: $cream;
    background-color: $red;
    border-color: $red;
}





.App.theme-blue {
    .Button.cream {
        border-color: $cream;
        color: $blue;
        background-color: $cream;
    }

    .Button.cream.hollow {
        color: $cream;
        background-color: transparent;
    }

    .Button.cream:hover,
    .Button.cream:active {
        color: $cream;
        background-color: $blue;
        border-color: $blue;
    }

    .Button.cream.hollow:hover,
    .Button.cream.hollow:active {
        color: $blue;
        background-color: $cream;
        border-color: $cream;
    }
}

.App.theme-grey {
    .Button.cream {
        border-color: $cream;
        color: $grey;
        background-color: $cream;
    }

    .Button.cream.hollow {
        color: $cream;
        background-color: transparent;
    }

    .Button.cream:hover,
    .Button.cream:active {
        color: $cream;
        background-color: $grey;
        border-color: $grey;
    }

    .Button.cream.hollow:hover,
    .Button.cream.hollow:active {
        color: $grey;
        background-color: $cream;
        border-color: $cream;
    }
}

.App.theme-gold {
    .Button.cream {
        border-color: $cream;
        color: $gold;
        background-color: $cream;
    }

    .Button.cream.hollow {
        color: $cream;
        background-color: transparent;
    }

    .Button.cream:hover,
    .Button.cream:active {
        color: $cream;
        background-color: $gold;
        border-color: $gold;
    }

    .Button.cream.hollow:hover,
    .Button.cream.hollow:active {
        color: $gold;
        background-color: $cream;
        border-color: $cream;
    }
}

.App.theme-red {
    .Button.cream {
        border-color: $cream;
        color: $red;
        background-color: $cream;
    }

    .Button.cream.hollow {
        color: $cream;
        background-color: transparent;
    }

    .Button.cream:hover,
    .Button.cream:active {
        color: $cream;
        background-color: $red;
        border-color: $red;
    }

    .Button.cream.hollow:hover,
    .Button.cream.hollow:active {
        color: $red;
        background-color: $cream;
        border-color: $cream;
    }
}

