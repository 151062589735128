@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.FlexibleContent > div {
    position: relative;
    display: flex;

    &:first-child {
        margin-top: $margin-top;
    }
}

.FlexibleContent > div:last-of-type {
    margin-bottom: $margin-top;

    &.floating_section {
        margin-bottom: 0;

        .FloatingSection {
            margin-bottom: 0;
        }
    }

    @include mobile {

        &.matrix_gallery,
        &.sidekick_image {
            margin-bottom: 0;
        }
    }
}

.FlexibleContent > div > * {
    flex: 1;
}

.FlexibleContent > .hero_carousel,
.FlexibleContent > .hero_image {
    &:first-child {
        margin-top: 0;
    }
}

@include mobile {

    .FlexibleContent > .contact_form {
        &:first-child {
            margin-top: 0;
        }
    }

}
