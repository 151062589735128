@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.HeaderTagOverlay {
    position: absolute;
    top: 0;
    left: $padding-sides;
    padding: 10px;
    padding-top: calc(#{ $header-height } * 0.25);

    background: $blue;
    color: $cream;
    font-family: 'TTOctas-Bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.2em;

    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}

.Header.collapsed .HeaderTagOverlay {
    padding-top: 10px;
}




.App.theme-blue {
    .HeaderTagOverlay { background: $blue; }
}

.App.theme-grey {
    .HeaderTagOverlay { background: $grey; }
}

.App.theme-gold {
    .HeaderTagOverlay { background: $gold; }
}

.App.theme-red {
    .HeaderTagOverlay { background: $red; }
}
