@import '../../styles/variables.scss';
@import '../../styles/fonts.scss';
@import '../../styles/mediaqueries.scss';

.Loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: $cream;
    z-index: 9999;

    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease-in-out;
}

.Loader.active {
    opacity: 1;
}

.Loader div.spinner  {
    position: relative;
    width: 200px;
    height: 200px;
}

.Loader div.spinner div.circle {

    position: absolute;
    left: 50%;
    top: 50%;

    border-radius: 50%;
    border: 5px solid transparent;
    border-left: 5px solid;
    border-left-color: $blue;

    animation: loading 1.5s linear infinite;

}

.Loader div.spinner.white div.circle {
    border-left-color: $white !important;
}

.Loader div.spinner div.one {
    width: 40px;
    height: 40px;
    animation-duration: .6s;
}

.Loader div.spinner div.two {
    width: 65px;
    height: 65px;
    animation-duration: 1s;
    animation-direction: reverse;
}

.Loader div.spinner div.three {
    width: 90px;
    height: 90px;
    animation-duration: 1.4s;
}


@keyframes loading {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}





.App.theme-blue {
    .Loader div.spinner div.circle {
        border-left-color: $blue;
    }
}

.App.theme-grey {
    .Loader div.spinner div.circle {
        border-left-color: $grey;
    }
}

.App.theme-red {
    .Loader div.spinner div.circle {
        border-left-color: $red;
    }
}
