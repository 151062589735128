@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.HeaderMobileTagBar {
    position: fixed;
    top: $header-mobile-height;
    left: 0;
    width: 100%;
    height: $header-mobile-tag-height;

    display: flex;
    justify-content: center;
    align-items: center;

    background: $blue;
    color: $cream;
    font-family: 'TTOctas-Bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.2em;

    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);

    z-index: 1000;
}




.App.theme-blue {
    .HeaderMobileTagBar { background: $blue; }
}

.App.theme-grey {
    .HeaderMobileTagBar { background: $grey; }
}

.App.theme-gold {
    .HeaderMobileTagBar { background: $gold; }
}

.App.theme-red {
    .HeaderMobileTagBar { background: $red; }
}
