@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

div.FeaturedArticle {
    @extend .margin-top;

    border-bottom: 1px solid $blue;

    @include mobile-less {
        margin-left: $padding-sides-mobile;
        margin-right: $padding-sides-mobile;
    }
}

div.FeaturedArticle div.date {
    @extend .h7;

    color: $red;
    font-size: 13px;
    line-height: 30px;
    font-weight: 600;

    border-bottom: 1px solid $blue;
}

div.FeaturedArticle div.inner-content h3 {

    @extend .h7;

    color: $red;
    font-size: 48px;
    line-height: 1;

    margin: $margin-top 0;

}

div.FeaturedArticle div.inner-content p {

    @extend .h7;

    font-size: 25px;
    line-height: 32px;
    max-width: 580px;

}

div.FeaturedArticle div.footer-content {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    margin-top: $margin-top;
    margin-bottom: $paragraph-top * 0.5;

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
    }

}

div.FeaturedArticle div.footer-content h4 {

    @extend .h7;

    color: $red;
    font-size: 13px;
    line-height: 1;
    font-weight: 600;

    margin-bottom: 0;

    @include mobile {
        margin-bottom: $margin-top;
    }

}
