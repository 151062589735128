@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.FooterMenu > h6 {

    @extend .footer;

    color: $cream;

    padding-bottom: $paragraph-top;
    margin-bottom: $paragraph-top;
    border-bottom: 2px solid $gold;

    @include tablet-less {
        padding-bottom: $paragraph-top * 0.5;
        margin-bottom: $paragraph-top * 0.5;
    }

}

.FooterMenu .MenuLink {

    @extend .footer;

    color: $cream;
    display: block;

    &:hover {
        color: $gold;
        cursor: pointer;
    }

}
